@charset "UTF-8";
// Version 2.8

@import 'iconfont_variables.scss';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('../assets/fonts/#{$icomoon-font-family}.eot?ytzhy5');
  src: url('../assets/fonts/#{$icomoon-font-family}.eot?ytzhy5#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/#{$icomoon-font-family}.ttf?ytzhy5') format('truetype'),
    url('../assets/fonts/#{$icomoon-font-family}.woff?ytzhy5') format('woff'),
    url('../assets/fonts/#{$icomoon-font-family}.svg?ytzhy5##{$icomoon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ahicon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1.2em;

  &.large {
    font-size: 1.6em;
  }

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Logo-Inverted-Lockup {
  &:before {
    content: $icon-Logo-Inverted-Lockup;
  }
}
.icon-Logo-Inverted-Only {
  &:before {
    content: $icon-Logo-Inverted-Only;
  }
}
.icon-Logo-Full {
  &:before {
    content: $icon-Logo-Full;
  }
}
.icon-Logo-Text-Only {
  &:before {
    content: $icon-Logo-Text-Only;
  }
}
.icon-admithub-logo {
  &:before {
    content: $icon-admithub-logo;
  }
}
.icon-bot {
  &:before {
    content: $icon-bot;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard;
  }
}
.icon-conversation {
  &:before {
    content: $icon-conversation;
  }
}
.icon-knowledge-base {
  &:before {
    content: $icon-knowledge-base;
  }
}
.icon-campaign {
  &:before {
    content: $icon-campaign;
  }
}
.icon-people {
  &:before {
    content: $icon-people;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-expand_less {
  &:before {
    content: $icon-expand_less;
  }
}
.icon-double-arrow-left {
  &:before {
    content: $icon-double-arrow-left;
  }
}
.icon-expand_more {
  &:before {
    content: $icon-expand_more;
  }
}
.icon-navigate_before {
  &:before {
    content: $icon-navigate_before;
  }
}
.icon-navigate_next {
  &:before {
    content: $icon-navigate_next;
  }
}
.icon-arrow_drop_down {
  &:before {
    content: $icon-arrow_drop_down;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-add {
  &:before {
    content: $icon-add;
  }
}
.icon-arrow_upward {
  &:before {
    content: $icon-arrow_upward;
  }
}
.icon-arrow_downward {
  &:before {
    content: $icon-arrow_downward;
  }
}
.icon-arrow_forward {
  &:before {
    content: $icon-arrow_forward;
  }
}
.icon-arrow_back {
  &:before {
    content: $icon-arrow_back;
  }
}
.icon-arrow_drop_up {
  &:before {
    content: $icon-arrow_drop_up;
  }
}
.icon-chat_bubble {
  &:before {
    content: $icon-chat_bubble;
  }
}
.icon-chat_bubble_outline {
  &:before {
    content: $icon-chat_bubble_outline;
  }
}
.icon-comment {
  &:before {
    content: $icon-comment;
  }
}
.icon-cached {
  &:before {
    content: $icon-cached;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;
  }
}
.icon-school {
  &:before {
    content: $icon-school;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-open_in_new {
  &:before {
    content: $icon-open_in_new;
  }
}
.icon-mobile_phone {
  &:before {
    content: $icon-mobile_phone;
  }
}
.icon-visibility {
  &:before {
    content: $icon-visibility;
  }
}
.icon-reply {
  &:before {
    content: $icon-reply;
  }
}
.icon-sort {
  &:before {
    content: $icon-sort;
  }
}
.icon-swap_vert {
  &:before {
    content: $icon-swap_vert;
  }
}
.icon-thumb_down {
  &:before {
    content: $icon-thumb_down;
  }
}
.icon-thumb_up {
  &:before {
    content: $icon-thumb_up;
  }
}
.icon-thumbs_up_down {
  &:before {
    content: $icon-thumbs_up_down;
  }
}
.icon-tv {
  &:before {
    content: $icon-tv;
  }
}
.icon-verified_user {
  &:before {
    content: $icon-verified_user;
  }
}
.icon-check_box {
  &:before {
    content: $icon-check_box;
  }
}
.icon-check_box_outline_blank {
  &:before {
    content: $icon-check_box_outline_blank;
  }
}
.icon-do_not_disturb {
  &:before {
    content: $icon-do_not_disturb;
  }
}
.icon-error {
  &:before {
    content: $icon-error;
  }
}
.icon-calendar_day {
  &:before {
    content: $icon-calendar_day;
  }
}
.icon-cancel {
  &:before {
    content: $icon-cancel;
  }
}
.icon-highlight_off {
  &:before {
    content: $icon-highlight_off;
  }
}
.icon-file {
  &:before {
    content: $icon-file;
  }
}
.icon-laptop {
  &:before {
    content: $icon-laptop;
  }
}
.icon-indeterminate_check_box {
  &:before {
    content: $icon-indeterminate_check_box;
  }
}
.icon-radio_button_checked {
  &:before {
    content: $icon-radio_button_checked;
  }
}
.icon-radio_button_unchecked {
  &:before {
    content: $icon-radio_button_unchecked;
  }
}
.icon-Toggle-off {
  &:before {
    content: $icon-Toggle-off;
  }
}
.icon-Toggle-on {
  &:before {
    content: $icon-Toggle-on;
  }
}
.icon-lock_open {
  &:before {
    content: $icon-lock_open;
  }
}
.icon-lock {
  &:before {
    content: $icon-lock;
  }
}
.icon-more_horiz {
  &:before {
    content: $icon-more_horiz;
  }
}
.icon-more_vert {
  &:before {
    content: $icon-more_vert;
  }
}
.icon-sentiment_very_satisfied {
  &:before {
    content: $icon-sentiment_very_satisfied;
  }
}
.icon-tag_faces {
  &:before {
    content: $icon-tag_faces;
  }
}
.icon-sentiment_satisfied {
  &:before {
    content: $icon-sentiment_satisfied;
  }
}
.icon-sentiment_neutral {
  &:before {
    content: $icon-sentiment_neutral;
  }
}
.icon-sentiment_dissatisfied {
  &:before {
    content: $icon-sentiment_dissatisfied;
  }
}
.icon-sentiment_very_dissatisfied {
  &:before {
    content: $icon-sentiment_very_dissatisfied;
  }
}
.icon-settings {
  &:before {
    content: $icon-settings;
  }
}
.icon-account_box {
  &:before {
    content: $icon-account_box;
  }
}
.icon-account_circle {
  &:before {
    content: $icon-account_circle;
  }
}
.icon-person {
  &:before {
    content: $icon-person;
  }
}
.icon-new_releases {
  &:before {
    content: $icon-new_releases;
  }
}
.icon-bookmark-selected {
  &:before {
    content: $icon-bookmark-selected;
  }
}
.icon-bookmark-empty {
  &:before {
    content: $icon-bookmark-empty;
  }
}
.icon-device_hub {
  &:before {
    content: $icon-device_hub;
  }
}
.icon-send {
  &:before {
    content: $icon-send;
  }
}
.icon-add_circle {
  &:before {
    content: $icon-add_circle;
  }
}
.icon-add_circle_outline {
  &:before {
    content: $icon-add_circle_outline;
  }
}
.icon-remove_circle {
  &:before {
    content: $icon-remove_circle;
  }
}
.icon-remove_circle_outline {
  &:before {
    content: $icon-remove_circle_outline;
  }
}
.icon-image {
  &:before {
    content: $icon-image;
  }
}
.icon-link_off {
  &:before {
    content: $icon-link_off;
  }
}
.icon-link {
  &:before {
    content: $icon-link;
  }
}
.icon-check_circle {
  &:before {
    content: $icon-check_circle;
  }
}
.icon-check_circle_outline {
  &:before {
    content: $icon-check_circle_outline;
  }
}
.icon-help {
  &:before {
    content: $icon-help;
  }
}
.icon-help_outline {
  &:before {
    content: $icon-help_outline;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-info_outline {
  &:before {
    content: $icon-info_outline;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-flag {
  &:before {
    content: $icon-flag;
  }
}
.icon-outlined-flag {
  &:before {
    content: $icon-outlined-flag;
  }
}
.icon-question-summary {
  &:before {
    content: $icon-question-summary;
  }
}
.icon-question {
  &:before {
    content: $icon-question;
  }
}
.icon-answer {
  &:before {
    content: $icon-answer;
  }
}
.icon-delete {
  &:before {
    content: $icon-delete;
  }
}
.icon-Stats {
  &:before {
    content: $icon-Stats;
  }
}
.icon-Book {
  &:before {
    content: $icon-Book;
  }
}
.icon-print {
  &:before {
    content: $icon-print;
  }
}
.icon-mail-outline {
  &:before {
    content: $icon-mail-outline;
  }
}
.icon-infinite {
  &:before {
    content: $icon-infinite;
  }
}
.icon-rss {
  &:before {
    content: $icon-rss;
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}
.icon-pocket {
  &:before {
    content: $icon-pocket;
  }
}
.icon-medium {
  &:before {
    content: $icon-medium;
  }
}
.icon-github {
  &:before {
    content: $icon-github;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-messenger {
  &:before {
    content: $icon-messenger;
  }
}
.icon-file_upload {
  &:before {
    content: $icon-file_upload;
  }
}
.icon-file_download {
  &:before {
    content: $icon-file_download;
  }
}
.icon-get_app {
  &:before {
    content: $icon-get_app;
  }
}
.icon-cloud_upload {
  &:before {
    content: $icon-cloud_upload;
  }
}
.icon-backup {
  &:before {
    content: $icon-backup;
  }
}
.icon-cloud_download {
  &:before {
    content: $icon-cloud_download;
  }
}
.icon-file_download_done {
  &:before {
    content: $icon-file_download_done;
  }
}
.icon-person_add {
  &:before {
    content: $icon-person_add;
  }
}
.icon-drag_indicator {
  &:before {
    content: $icon-drag_indicator;
  }
}
.icon-drag_handle {
  &:before {
    content: $icon-drag_handle;
  }
}
.icon-translate {
  &:before {
    content: $icon-translate;
  }
}
.icon-pin {
  &:before {
    content: $icon-pin;
  }
}
.icon-forward {
  &:before {
    content: $icon-forward;
  }
}
.icon-copy {
  &:before {
    content: $icon-copy;
  }
}
.icon-label {
  &:before {
    content: $icon-label;
  }
}
.icon-label_off {
  &:before {
    content: $icon-label_off;
  }
}
.icon-archive {
  &:before {
    content: $icon-archive;
  }
}
.icon-collection {
  &:before {
    content: $icon-collection;
  }
}
.icon-trending_up {
  &:before {
    content: $icon-trending_up;
  }
}
.icon-visibility_off {
  &:before {
    content: $icon-visibility_off;
  }
}
.icon-historyrestore {
  &:before {
    content: $icon-historyrestore;
  }
}
.icon-notes {
  &:before {
    content: $icon-notes;
  }
}
.icon-multiple-choice {
  &:before {
    content: $icon-multiple-choice;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
.icon-minimize {
  &:before {
    content: $icon-minimize;
  }
}
.icon-pause {
  &:before {
    content: $icon-pause;
  }
}
.icon-reset {
  &:before {
    content: $icon-reset;
  }
}
.icon-attribute {
  &:before {
    content: $icon-attribute;
  }
}
.icon-contacts-inverse {
  &:before {
    content: $icon-contacts-inverse;
  }
}
.icon-script-inverse {
  &:before {
    content: $icon-script-inverse;
  }
}
.icon-campaign-inverse {
  &:before {
    content: $icon-campaign-inverse;
  }
}
.icon-knowledge-base-inverse {
  &:before {
    content: $icon-knowledge-base-inverse;
  }
}
.icon-attribute-inverse {
  &:before {
    content: $icon-attribute-inverse;
  }
}
