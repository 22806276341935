@import 'scss/_variables.scss';

.cookies-banner-container {
  position: absolute;
  bottom: 60px;
}
.cookies-banner-content {
  background: $mainstay-dark-blue;
  color: white;
  padding: 20px;
  max-width: 1200px;
  border-radius: 20px;
  z-index: 10000;
}
