@import 'scss/_variables.scss';

.priority-nav-root {
  width: 100%;
  position: relative;
  white-space: nowrap;
}

.priority-nav-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 1;
}

.priority-nav-item {
  display: inline-block;
}

.dropdown-icon-container {
  background-color: transparent;
  border-radius: 3px;
  padding: 0px 8px;

  &:hover {
    background-color: $mainstay-blue-10;
  }

  &:active {
    background-color: $mainstay-focused-gray !important;
  }

  &:focus {
    background-color: $mainstay-focused-gray !important;
  }
}

.dropdown-icon {
  background-color: transparent;
  color: $mainstay-dark-blue-65;
  vertical-align: middle;
}

.dropdown-container {
  border-radius: 3px;
}

.dropdown-item {
  max-width: 208px;
  text-align: left;
  padding: 8px;
  align-items: center;
  align-self: stretch;
  background-color: transparent;
  color: $mainstay-dark-blue;
  /* Mainstay Paragraph / Paragraph */
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
  &:hover {
    background-color: transparent;
    color: $mainstay-dark-blue;
  }
}
