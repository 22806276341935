@import 'scss/_variables.scss';

.contact-table {
  // This can be adjusted based on the type of content in the table
  min-width: 1000px;

  .student-row {
    text-decoration: none !important;

    &:hover .row {
      background-color: $mainstay-dark-blue-10;
    }

    &.active .row {
      background-color: $new-ui-selected-light;
      opacity: 1;
    }
  }
}

@for $i from 0 through 50 {
  .table-min-width-#{$i} {
    min-width: 1000px + ($i * 180px) !important;
  }
}
