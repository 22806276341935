@import 'scss/_variables.scss';

.tab-link {
  @include unstyled-link;
  border-bottom: 3px solid transparent;

  &.active {
    color: $new-ui-primary;
    border-bottom: 3px solid $new-ui-primary;
  }
}
