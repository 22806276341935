@import 'scss/_variables.scss';

.search-result {
  border: 1px solid $mainstay-dark-blue-20;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 16px;
}

.search-result-text {
  word-break: break-all;
}
