@import 'scss/_variables.scss';

.ai-menu {
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 38px;
  width: 100%;
  flex-shrink: 1;

  &.wide {
    border: 1px solid #d8dee8;
    padding: 0px 8px 0px 0px;
  }
  &:hover {
    background-color: $mainstay-dark-blue-10;
  }
  &:active {
    background-color: $mainstay-dark-blue-10;
  }
  &:focus {
    background-color: $mainstay-dark-blue-10;
  }
  &:focus-visible {
    box-shadow: none !important;
    outline: -webkit-focus-ring-color auto 1px !important;
  }
}

.disabled-background {
  background-color: transparent !important;
  &:hover {
    background-color: transparent !important;
  }
}

.disabled-opacity {
  opacity: 0.6;
}

.firefly {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 6px;
}

.firefly-text {
  color: $mainstay-dark-blue;
  font-family: Source Sans Pro;
  font-size: 12.8px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  /* 16px */
  letter-spacing: 0.512px;
  text-transform: uppercase;
}

.ai-actions-list {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.ai-actions-list-item {
  border-radius: 3px !important;
  padding: 4px 8px !important;
  cursor: pointer;
  color: $secondary-teal;
  background-color: $mainstay-gray-20 !important;
  border: 1px solid $mainstay-gray;
  font-size: 0.8rem;
  &:hover:not(:disabled) {
    outline: 1px solid $secondary-teal !important;
    color: $secondary-teal !important;
  }
}

.h-30px {
  height: 30px;
}

.response-counter {
  position: relative;
  top: 1px;
}

.ai-action {
  white-space: nowrap;
  margin-right: 4px;
}

.ai-action-dropdown-icon {
  background-color: transparent;
  border-radius: 3px;
  padding: 0px 8px 0px 0px;

  color: $mainstay-dark-blue-65;
  &.narrow {
    vertical-align: middle;
    height: 100%;
    transform: translateY(2px);
  }
}

.ai-menu-in-context-menu {
  padding: 7px 10px 9px 12px !important;
  max-height: 40px !important;
  border: $mainstay-focused-gray 1px solid !important;
  background-color: $mainstay-gray-backgrounds !important;
  color: $mainstay-dark-blue;

  &:hover {
    background-color: $mainstay-dark-blue-10 !important;
  }

  &:active {
    background-color: $mainstay-dark-blue-10 !important;
  }

  &:focus {
    background-color: $mainstay-dark-blue-10 !important;
  }
}

.ai-response-container {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  height: 250px;
  background: white;
  border: 1px solid $blue-grey-100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .no-outline-on-focus {
    &:focus {
      outline: none !important;
    }
  }
  .ahicon-container-btn {
    .ahicon {
      top: 4px;
      position: relative;
      margin: 0px !important;
    }
  }
}

button:disabled {
  svg {
    fill: $mainstay-disabled-gray !important;
  }
  path {
    fill: $mainstay-disabled-gray !important;
  }
  span {
    opacity: 0.6;
  }
}

.overflow-scroll {
  overflow: scroll !important;
}
