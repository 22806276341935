@import 'scss/_variables.scss';

.mainstay-toast {
  cursor: default;
  border-radius: 10px;
  box-shadow: 0 12px 50px $mainstay-dark-blue-20;
  width: 407px;
  min-height: 54px;
  margin: 0 0 1rem;
  padding: 0;

  .Toastify__toast-body {
    margin: 0;
    padding: 0;
  }
}
.mainstay-toast-info,
.mainstay-toast-success,
.mainstay-toast-warning,
.mainstay-toast-error {
  @extend .mainstay-toast;
  background-color: $mainstay-almost-black;
}
