@import 'scss/_variables.scss';

.overview-container {
  display: flex;
  flex-wrap: wrap;
  .overview-row {
    display: flex;
    min-width: 0;
    flex-basis: 50%;
    padding-right: 2rem !important;
    max-width: 600px;
    align-items: center;
  }
}

.flex-100 {
  flex-basis: 100%;
}

.flex-50 {
  min-width: 0;
  flex-basis: 50%;
}

.br-3px {
  border-radius: 3px;
}

.fs-11px {
  font-size: 11px;
}

.campaign-container-scroll {
  overflow-y: scroll;
}

.campaign-tabs-scroll {
  overflow-y: visible !important;
  max-height: none !important;
}

.sticky-title {
  position: sticky;
  top: 0rem;
  background: $mainstay-gray-20;
  z-index: 4;
}

.sticky-description {
  position: sticky;
  top: 2rem;
}

.sticky-tabs {
  position: sticky;
  top: 1.25rem;
  background: $mainstay-gray-20;
  z-index: 1;
}

.rescheduler-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: fit-content;
  padding: 10px;
  z-index: 3000;
}

.trigger-toggle-container {
  padding: 16px;
  margin-bottom: 24px;
  background-color: $mainstay-blue-10;
  border: 1px solid $mainstay-blue-80;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
