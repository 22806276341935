@import 'scss/_variables.scss';

.lang-search-container {
  width: 100%;
  border: 0;
  z-index: inherit;
}
.dropdown-container {
  top: 0;
  left: 0;
  width: 100%;
  max-height: 85%;
  z-index: 2;
  position: absolute;
}
.lang-dropdown {
  border: 0;
  padding: 0;
  margin: 0;
  z-index: inherit;
}

.webchat-modal {
  position: absolute !important;
  top: -4px;
  left: 48% !important;
  width: 98% !important;
  margin: 7px;
}

.language-select-modal {
  @extend .webchat-modal;
  height: 81%;
}

.lang-dropdown-inner {
  z-index: 3;
  color: black !important;
  background: white;
  border: 0;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.dropdown-toggle-focus-outline {
  &:focus {
    box-shadow: none !important;
    outline: auto 1px $mainstay-dark-blue !important;
  }
}

.plain-input {
  -moz-transition: none !important;
  -webkit-transition: none !important;
  -o-transition: color 0 ease-in !important;
  transition: none !important;
  background-image: none !important;
  border-radius: 5px !important;
  background-clip: unset !important;
}

.lang-dropdown-option {
  border-radius: 3px;
  &:hover {
    color: white;
    background-color: $mainstay-dark-blue-65 !important;
  }
}

.lang-dropdown--active {
  color: white;
  background-color: $mainstay-dark-blue-65 !important;
}

.language-select {
  min-width: 88%;
  height: 100%;
  position: absolute;

  input:focus {
    outline: -webkit-focus-ring-color auto 1px !important;
  }
}
