.spinner {
  height: auto;
  &.sm {
    width: 1rem;
  }
  &.md {
    width: 3rem;
  }
  &.lg {
    width: 5rem;
  }
}
