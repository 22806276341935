.user-icon-inner {
  width: 2.8125rem;
  height: 2.8125rem;
  font-size: 1.5rem;
  user-select: none;
  &.md {
    width: 2.25rem;
    height: 2.25rem;
    font-size: 1.2rem;
  }
  &.sm {
    width: 1.6875rem;
    height: 1.6875rem;
    font-size: 0.9rem;
  }
  .icon-image {
    border-radius: 100%;
    width: 30px;
    height: 30px;
  }
}

.user-online-status {
  width: 0.75rem;
  height: 0.75rem;
  border: solid 2px white;
  position: relative;
  left: 1rem;
  top: -0.5rem;
  font-size: 0.5em;

  &.ahicon {
    top: -1.1em;
    font-size: 0.7em;
    border: solid 1px white;
  }
}

.user-icon-inner.outline {
  border: 2px solid white;
}
