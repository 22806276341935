@import 'scss/_variables.scss';

.nav-side-bar {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: $admithub-side-bar-min-width;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  min-height: 100vh;
  background-color: $mainstay-dark-blue;
  color: $blue-grey-100;
  //new-design
  width: $admithub-side-bar-min-width;
  padding-top: 25px;

  .nav-side-bar-footer {
    display: flex;

    ul {
      margin-bottom: 1rem;
    }
  }

  .ah-only-color {
    color: $amber-500;
  }

  .global-nav-link {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0.625rem 0.3rem;
    width: 100%;
    text-decoration: none;
    background: unset;
    border: unset;

    &.no-padding {
      padding: 0;
    }

    .nav-icon {
      color: $white;
      opacity: 0.6;
      font-size: 1.35rem;
      line-height: 1;
      padding: 0.1rem 0 0;
    }

    .nav-label {
      display: block;
      position: absolute;
      opacity: 0;
      pointer-events: none;
      z-index: 99;
      color: $mainstay-cream-30;
      background-color: $mainstay-almost-black-80;
      padding: 0.3rem 0.6rem;
      left: 90%;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 15px;
      width: auto;
      white-space: nowrap;
      transition: all 0.2s ease;
      font-size: 0.95rem;
    }

    // Inherited Active States
    &.global-nav-link-active {
      .nav-icon {
        opacity: 1;
      }

      &:after {
        content: ' ';
        display: block;
        height: 90%;
        width: 5px;
        position: absolute;
        right: 0;
        top: 5%;
        background-color: $mainstay-mint;
        border-radius: $border-radius 0 0 $border-radius;
      }

      .no-side-bar {
        background-color: transparent !important;
      }
    }

    &.ah-logo-main {
      padding: 0.8rem 0;

      .nav-icon {
        font-size: 1.6rem;
      }
    }

    &.ah-logo-main {
      padding: 0.8rem 0;

      .nav-icon {
        font-size: 1.6rem;
        opacity: 1;

        &:hover {
          opacity: 1;
        }
      }
    }

    .org-initial-link {
      text-align: center;
    }
  }

  // Hover States, only if a link
  .global-nav-link {
    &:hover:not(.ah-logo-main) {
      .nav-icon {
        opacity: 0.8;
      }

      .nav-label {
        opacity: 1;
        left: 98%;
      }
    }
  }

  button.global-nav-link {
    &:focus {
      box-shadow: none !important;
    }
  }

  .sidebar-items-container {
    justify-content: center;

    ul {
      width: 100%;
    }
  }

  .golden-link {
    z-index: 99999999999;
  }
}

.nav-bar-page-nav {
  height: #{$nav-height} !important;
}

.popout-org-switcher {
  max-height: 300px;
  left: 105%;
  top: 0;
  white-space: nowrap;
}

.popout-org-switcher-menu {
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
