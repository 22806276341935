// overrides to prevent overflow issue causing horizontal scrollbars to show
.rc-time-picker-panel-select li {
  padding-left: 0.5rem !important;
  width: unset !important;
}

.DayPickerInput-Container {
  input {
    border: 0;
  }
}

.DayPickerInput-Overlay {
  // Ensure overlay stays above page.
  z-index: 10;
}

// HACK(chdsbd): Attempt to square up ellipses into circles.
// I think the main problem is the use of table-based layouts in the component.
.DayPicker-Month {
  min-width: 281px;
}

.repeat-interval-dropdown {
  .form-control {
    min-width: 325px !important;
  }

  .dropdown-icon {
    left: unset;
  }
}

.trigger-type-group {
  margin: 0 2.1rem 2.1rem 2.1rem;
}

.disabled-option {
  opacity: 0.6;
  pointer-events: none;
}
