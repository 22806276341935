@import 'scss/_variables.scss';

.knowledge-seed-edit-panel-slide-container-appear,
.knowledge-seed-edit-panel-slide-container-exit-done,
.knowledge-seed-edit-panel-slide-container-enter {
  width: 0px;
  border-left: 1px solid;
  border-color: white;
  transition: width 200ms ease-in;
}

.knowledge-seed-edit-panel-slide-container-enter-done,
.knowledge-seed-edit-panel-slide-container-exit {
  width: 800px;
  border-left: 1px solid;
  border-color: $gray-300;
  transition: width 200ms ease-in;
}

.knowledge-seed-edit-panel .top-menu {
  font-size: 14pt;
}

.ah-knowledge-seeder-icon {
  color: $mainstay-dark-blue-65 !important;
  background: transparent !important;
  height: 24px !important;
  width: 24px !important;
  padding-bottom: 0.125rem;
}

.knowledge-panel-title {
  color: $mainstay-dark-blue-80;
  /* Mainstay Header/H5 */
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 24px */
}

.ah-help-icon {
  width: 16px !important;
}

.knowledge-answer-group-show-ui-on-hover {
  .show-on-hover {
    display: none;
  }
  &:hover {
    .show-on-hover {
      display: initial;
    }
  }
}

.shadow-border {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
}

.fuzzy-question-container {
  width: 456px;
  height: 88px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid transparent;

  &.loading-fuzzy-question {
    display: flex;
    padding: 4px 12px 6px 12px;
    flex-direction: column;
    align-items: flex-start;
  }

  &.generated-fuzzy-question {
    border: 1px solid $mainstay-gray-labels;
  }
}

.move-trash-can {
  transform: translateX(1px);
}

.move-understanding-summary-icon {
  transform: translateY(4px);
}

.move-search-result {
  transform: translateX(16px);
}

.move-chevron-up {
  transform: translateY(-1px);
}

.dont-hide-behind-footer {
  padding-bottom: 103px;
}

.fuzzy-question-cancel-button {
  background-color: transparent;
  color: $mainstay-dark-blue-65;
  text-decoration: none;
  &:hover {
    text-decoration: underline !important;
  }
}

.loading-row {
  height: 14px;
  padding: 0px 12px;
  margin: 8px 0px;

  &.top {
    margin-top: 4px;
    width: 100%;
  }

  &.bottom {
    width: 50%;
    margin-top: 0px;
  }
}

.switch-text {
  position: absolute;
  font-family: Source Sans Pro;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.no-approved-answer-content {
  transform: translateX(-4px);
}

.add-new-understanding-footer {
  box-shadow: 0px -2px 4px -2px rgba(16, 24, 40, 0.06),
    0px -4px 8px -2px rgba(16, 24, 40, 0.1);
}

.move-create-question-button {
  transform: translateX(-8px);
}

.similar-understandings-border-outer {
  border-radius: 4px;
  border: 1px solid #d8dee8;
}

.suggested-answers-border {
  border-radius: 4px;
  border: 1px solid #d8dee8;
  background: #fff;

  &.expanded {
    border-radius: 4px 4px 0px 0px;
  }
}

.suggested-answers-border-expanded {
  border-radius: 0px 0px 4px 4px;
  border-left: 1px solid #d8dee8;
  border-right: 1px solid #d8dee8;
  border-bottom: 1px solid #d8dee8;
  background: #fff;
}

.dropdown-border-top {
  border-radius: 4px;
  border-left: 1px solid #d8dee8;
  border-right: 1px solid #d8dee8;
  border-bottom: 1px solid #d8dee8;
  background: #fff;
}

.absolute-chevron {
  margin-top: 2px;
}

.absolute-collapsible-title {
  margin-left: 28px;
}

.move-suggested-response-content-left {
  transform: translateX(5px);
}
