@import '_variables.scss';

// Table Styles
//
// Panel widths are not set by default,
// use bootstrap columns to set widths
//
// Table (optional)
// >> Table Row (.table-row)
// >>> Table "Cells" (just uses standard col-*)

// This is a container around the table to effect scrolling
.overflow-x-table {
  overflow-x: auto;
}

// This effects the overflow container, only when placed in a main panel
.panel-main {
  .overflow-x-table {
    margin: 0 (-$panel-main-padding-x);
    padding-left: $panel-main-padding-x;

    .table {
      padding-right: $panel-main-padding-x;
    }
  }
}

.table {
  border-bottom: $border;
  margin-bottom: 1.5rem;
}
.table-row {
  display: flex;
  flex-wrap: wrap;
  border-top: $border;
  padding: $spacer 0;

  // This is adopted from the bootstrap ".no-gutters" utility
  // but we apply it directly to table-row
  // so there's only one class to worry about
  // this means the subsequent columns ("table cells") need padding utils
  > .col,
  > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}
.table-col {
  text-overflow: ellipsis;
}
.table-subheader {
  border-top: 2px solid $gray-600;
}
