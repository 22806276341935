@import 'scss/_variables.scss';

.pill {
  background-color: $mainstay-dark-blue-05;
  border-style: solid;
  border-width: 1px;
  border-color: $mainstay-dark-blue-65;
  color: $mainstay-dark-blue-80;
  font-weight: normal !important;
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.table-pill {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block !important;
}
