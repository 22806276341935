@import 'scss/_variables.scss';

.mainstay-drawer-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  height: auto;
  width: 100%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1), 0px 8px 22px rgba(0, 0, 0, 0.27);
  background-color: $white;
  padding: 15px 30px;
}
