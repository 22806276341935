.unapproved {
  .circle {
    stroke: #c5c9ea;
  }

  &.pointer:hover,
  &.pointer:active {
    .circle {
      stroke: #0fc65b;
    }
  }
}
