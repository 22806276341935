@import 'scss/_variables.scss';

.script-editor {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  overflow: hidden;
}

.script-editor-container {
  position: inherit;
}

.edit-script-container-error {
  border-style: solid;
  border-width: 1px;
}

.edit-script-type {
  margin-left: 0.3rem;
}

.script-delete-node {
  margin-right: 0.7rem;
  color: $mainstay-dark-blue-80;
}

.draft-error {
  background: $input-bg;
}

.edit-script-node-header {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
