.download-link {
  position: relative;
  .tooltip {
    opacity: 0;
  }
}

.tooltip {
  top: 2rem;
  left: -1.5rem;
}

.download-link:hover {
  .tooltip {
    opacity: 1;
  }
}
