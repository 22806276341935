@import 'scss/_variables.scss';
$sidebar-width: 240px; // 15rem;
$dashboards-breakpoint: 1024px;

$chart-height: 400px;

.chart-drag-indicator {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: grab;
  &:hover {
    > span {
      color: $mainstay-dark-blue !important;
    }
  }
  &:active {
    cursor: grabbing;
  }
}

.draggable-chart {
  height: $chart-height;
}

$grid-gap: 8px;

.reports-bundle {
  display: flex;
  flex-wrap: wrap;
  gap: 0px $grid-gap;
  height: $chart-height + $grid-gap;
  .report-download {
    position: relative;
    height: ($chart-height - $grid-gap)/2; // offset to add the horizontal gap
    max-width: calc(50% - #{$grid-gap}/ 2);
    flex-basis: calc(50% - #{$grid-gap}/ 2);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .chart-drag-indicator {
      top: 0px;
      left: 0px;
    }
  }
}

// prevent Line Chart dots from getting cut off
.recharts-surface {
  width: 101%;
}

.muuri-item-shown {
  width: 50%;
  min-width: 432px;
}

.chart-size-narrow {
  width: 50%;
  @media (max-width: $dashboards-breakpoint) {
    width: 100%;
  }
}

.ms-page-content--expanded {
  *.chart-size-narrow {
    width: 50%;
    @media (max-width: ($dashboards-breakpoint + $sidebar-width)) {
      width: 100%;
    }
  }
}

.chart-size-wide {
  width: 100%;
}

.muuri-item-dragging {
  transition: none;
  z-index: 100 !important;
  will-change: transform;
  .card {
    border: 1px solid $mainstay-dark-blue;
  }
}

.muuri-item-placeholder {
  opacity: 0.4;
}
