@import 'scss/_variables.scss';

.ms-toggle--on {
  &:hover .react-switch-bg {
    background-color: $mainstay-success-700 !important;
  }
  &:focus,
  &:focus-within {
    box-shadow: 0px 0px 0px 4px $mainstay-focused-gray !important;
  }
}
.ms-toggle--off {
  &:hover .react-switch-bg {
    background-color: $mainstay-dark-blue !important;
  }
  &:focus,
  &:focus-within {
    box-shadow: 0px 0px 0px 4px $mainstay-focused-gray !important;
  }
}
