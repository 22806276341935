@import 'scss/_variables.scss';
@import 'scss/_iconfont/iconfont_variables.scss';
@import 'components/TrendsV2/CustomizableDashboard.scss';

.chart-error-container {
  vertical-align: middle;
  text-align: center;
  height: 300px;
  max-height: 300px;
  display: flex;
  flex-direction: flex-column;
  align-items: center;
}

.chart-error {
  border-radius: 6px;
  border: 1px solid #d8dee8;
  height: 160px;
  width: 360px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 24px;
  color: $mainstay-dark-blue-65;
  text-align: center;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.default-chart-type-button {
  background-color: transparent;
  color: $secondary-teal;
  width: fit-content;
  &:hover {
    text-decoration: underline;
  }
}

.italic-label {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
}
