@import 'scss/_variables.scss';

.editor {
  border: 1px solid $blue-grey-100;
  border-radius: 1%;
}

.DraftEditor-root {
  min-height: 6rem;
  cursor: text;
}

.DraftEditor-editorContainer,
.public-DraftEditor-content {
  height: 100%;
  min-height: 6rem;
  padding-left: 0.5rem;
  padding-right: 2rem;
  color: $mainstay-dark-blue;
  font-family: $font-family-base;
}

.public-DraftEditorPlaceholder-inner {
  color: #ccc;
  display: block;
  pointer-events: none;
}

.public-DraftEditorPlaceholder-root {
  font-family: $font-family-base;
  padding-left: 1rem;
}

.default-answer {
  font-family: $font-family-base;
  font-weight: $font-weight-bold;
}

.attribute-card-container {
  z-index: 5070 !important;
  position: relative;
  width: 0px;
  height: 0px;
}

.attribute-card {
  position: absolute;
  width: 100px;
  height: 30px;
  border: 1px;
}

.new-page-link {
  text-decoration: none !important;
}

div[role='tooltip'] {
  opacity: 1;
}

.save-button {
  background-color: $admithub-primary;
  color: white;
}

.emoji-spacing {
  display: inline-block;
  position: relative;

  &:before {
    content: attr(data-emoji);
    position: absolute;
    margin-left: 3px;
  }

  span {
    span {
      letter-spacing: 1.5em;
      width: 1.5em;
      display: inline-block;
    }
  }
}

.attribute-institution {
  background: #f3e4f7;
}
.attribute-contact {
  background: #ffe9ab;
}

.attribute-block {
  padding-bottom: 1px;
  white-space: nowrap;
  .ahicon {
    display: inline-block !important;
    max-width: 0.8rem;
    font-size: 0.9rem !important;
    margin-right: 4px;
  }
}

.text-toggler {
  right: 16px;
  top: 0px;
}

.thumbs-down {
  margin-left: 65%;
  margin-top: 50%;
  z-index: 1000;
}

.max-w-400px {
  max-width: 400px;
}

.shift-down-4px {
  transform: translateY(4px);
}
