@import 'scss/_variables.scss';

$sidebar-width: 15rem;

.mainstay-sidebar {
  width: $sidebar-width !important;
  min-width: $sidebar-width !important;
  transform: translateX(-#{$sidebar-width});
  flex-shrink: none;
  padding: 0px;
  background-color: transparent;
  z-index: 10;
}

.mainstay-sidebar-sections-container {
  display: flex;
  flex-direction: column;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  background-color: $mainstay-cream-30;
  border-right: 1px solid $mainstay-gray;
  height: 100%;
  width: 100%;
  padding: 24px 8px;
  h4 {
    color: $mainstay-dark-blue;
    padding: 12px 0px 0px 16px;
    margin-bottom: 0px;
  }
}

.mainstay-sidebar-link {
  &.active {
    background: $mainstay-dark-blue-05;
    border: 1px solid $mainstay-dark-blue-05;
    border-radius: 75px;
    color: $mainstay-dark-blue !important;
  }

  &:not(.active) {
    border: 1px solid transparent;
    color: $mainstay-dark-blue-65 !important;
  }
}

.mainstay-sidebar-expand-list {
  border-top: 1px solid $mainstay-dark-blue-20;
  border-bottom: 1px solid $mainstay-dark-blue-20;
}

.min-height-250px {
  min-height: 250px !important;
}
