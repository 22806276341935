@import 'scss/_variables.scss';

.mainstay-sidebar-expand-list-top-shadow {
  box-shadow: inset 0rem 0.5rem 0.5rem -0.5rem $blue-grey-100;
}

.mainstay-sidebar-expand-list-bottom-shadow {
  box-shadow: inset 0rem -0.5rem 0.5rem -0.5rem $blue-grey-100;
}

.mainstay-sidebar-expand-list-both-shadow {
  box-shadow: inset 0rem 0.5rem 0.5rem -0.5rem $blue-grey-100,
    inset 0rem -0.5rem 0.5rem -0.5rem $blue-grey-100;
}
