@import 'scss/_variables.scss';

.disabled-input {
  background-color: #fafbfc !important;
  cursor: not-allowed;
}

.disabled-icon {
  color: #babdbf !important;
}

.daypicker-align-left {
  left: auto !important;
  right: 0;
}

.input-react-select {
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  &:disabled {
    border-color: hsl(0, 0%, 90%);
  }
}

.react-select-labeled {
  .label {
    position: absolute;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
    left: $input-padding-x;
    top: calc(#{$input-padding-y} + 0.5rem);
    color: rgba($input-color, 0.7);
    font-size: $font-size-base;
  }
  .label--active {
    font-size: $font-size-xs;
    top: calc(#{$input-padding-y} * 0.45);
  }
  .label--focused {
    color: $admithub-primary;
  }
  .label--error {
    color: $new-ui-danger !important;
  }
}

.read-only-filter-row {
  padding-right: 1rem !important;
  padding-bottom: 0.75rem !important;
}
