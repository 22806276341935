@import 'scss/_variables.scss';

/* Remove all the default styling for the TimePicker input */
.rc-time-picker-input {
  width: 100%;
  position: relative;
  display: unset;
  padding: unset;
  height: unset;
  cursor: unset;
  font-size: unset;
  line-height: unset;
  color: unset;
  background-color: unset;
  background-image: unset;
  border: unset;
  border-radius: unset;
  box-shadow: unset;
  transition: unset;
  &:focus {
    outline: unset;
  }
}

.rc-time-picker-panel-clear-btn {
  line-height: 1.4;
  svg {
    width: 12px;
  }
}

/* Bump font size to be equal to form inputs */
.rc-time-picker-panel-inner,
.rc-time-picker-panel-clear-btn-icon,
.rc-time-picker-panel-select {
  font-size: 1rem;
}

/* Clear styling for disabled stated */
.rc-time-picker-input[disabled] {
  &:hover {
    background: unset;
  }
  color: unset;
  background: unset;
  cursor: unset;
}

.rc-time-picker-input::placeholder {
  color: $mainstay-dark-blue-50;
}

/* We need to define a custom style for the disabled case */
.form-control.rc-time-picker.disabled {
  background: $input-disabled-bg;
  opacity: 1;
}
