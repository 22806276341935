@import 'scss/_variables.scss';

// https://www.benmarshall.me/max-character-length-ellipsis-css/
// https://stackoverflow.com/a/51973610
.truncate-answer {
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
