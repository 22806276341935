.vcard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
}

.vcard--border {
  border: 1px solid #e0e0e0;
}

.vcard__img {
  border-radius: 50%;
  width: 65px;
  height: 65px;
  object-fit: cover;
}
