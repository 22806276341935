@import 'scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.conv-v2-conversation-container {
  min-width: 480px;
  box-shadow: 0px 12px 50px $mainstay-dark-blue-20;
  margin: 30px 0 35px;
}

.conv-v2-profile-position {
  position: absolute;
  right: 9px;
}

.conv-v2-profile-icon {
  &:hover {
    fill: $mainstay-dark-blue-70;
  }

  &.active {
    fill: $mainstay-dark-mint;
  }
}

@include media-breakpoint-up(xxl) {
  .conv-v2-profile-icon {
    fill: $mainstay-dark-mint !important;
  }
}

.conv-v2-translate-icon-padding {
  margin-top: 14px;
  margin-left: 12px;
}

.conv-v2-primary-border {
  border: 2px solid $mainstay-dark-mint;
}

@mixin conv-v2-fadeout {
  height: 52px;
  position: absolute;
  bottom: 0;
  width: calc(100% - 1rem);
  // allow users to click through fadeout div to access messages underneath.
  pointer-events: none;
}

.conv-v2-fadeout-up {
  @include conv-v2-fadeout;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $white 100%);
}

.conv-v2-fadeout-down {
  @include conv-v2-fadeout;
  top: 0;
  background: linear-gradient(0, rgba(255, 255, 255, 0) 0%, $white 100%);

  &.flagged-present {
    top: 45px;
  }
}

.conv-v2-live-chat-btn {
  background-color: $mainstay-cream-30;
  border-radius: 85px;
}

.conv-v2-live-chat-btn-locked {
  background-color: $mainstay-cream-30;
  border-radius: 85px;
  padding-top: 9.5px;
  padding-bottom: 9.5px;
  line-height: 22.4px;
  display: flex;
  align-items: center;
  justify-content: between;
  cursor: auto;
  pointer-events: none;
  font-size: 16px;
  margin-top: 8px;
}

.conv-v2-live-chat-lock-icon {
  height: 21px;
  width: 16px;
  margin-right: 8px;
}

.conv-v2-und-expand-tab-border {
  border-bottom: 2px solid $blue-grey-600;
}

.conv-v2-centered-wrapper,
.conv-v2-empty-conversation-label {
  min-width: 480px;
}

.send-msg-footer {
  flex-direction: column;

  .reply-flag-box-container {
    height: 0;
    line-height: 28px;
    transition: height 1s;
    overflow: hidden;
  }

  .reply-flag-box-container-show {
    height: 28px;
    margin-bottom: 0.625rem;
  }

  .reply-flag-content {
    display: flex;
    border-radius: 5px;
    font-size: 13px;
    color: $mainstay-dark-blue;
  }

  .reply-flag-msg {
    width: 100%;
  }

  button > svg {
    height: 0.6rem;
  }
}

.no-conversations-container {
  height: 100%;
  position: relative;

  .no-conversations-content {
    svg {
      .st0 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: #f23661;
      }

      .st1 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: #092e63;
      }

      width: 80%;
    }

    width: 280px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.conversations-detail-tab {
  .tab-link {
    cursor: pointer;
    padding: 6px 12px !important;

    &:hover {
      border-bottom-color: #667085;
    }

    &:first-child {
      margin-left: 2em !important;
      margin-right: 6px !important;
    }

    &:not(:first-child) {
      margin: 0px 6px !important;
    }
  }
}

.scroll-to-bottom-container {
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 99999;
  transform: translateX(-50%);
}
