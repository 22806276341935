// see: https://getbootstrap.com/docs/4.1/getting-started/theming/#variable-defaults
// https://github.com/twbs/bootstrap/blob/0e8831505ac845f3102fa2c5996a7141c9ab01ee/scss/_variables.scss
$admithub-primary: #0c785e;

$admithub-orange: #f4511e;
$admithub-baby-blue: #d3fffc;
$admithub-violet: #3845ae;
$admithub-slate-blue: #6a31c5;
$admithub-cornflower-blue: #5c90f2;
$admithub-green: #37b669;

$link-primary: #0c785e;
$link-primary-hover: #1a8d7a;
$secondary-teal: $link-primary;

$nav-height: 41px;
$triage-nav-height: 64px;
$admithub-side-bar-min-width: 75px;

// TODO
// Adding "in between" value to Bootstrap's gray scale
// Likely can remove and tweak actual scale once we've rolled out new designs across site
$gray-150: #edeeee;
$gray-40: #9f9f9f;

// Colors ported over from Mascot color scheme
$blue-grey-005: #fbfbfb;
$blue-grey-010: #eff3f5;
$blue-grey-015: #e4ebef;
$blue-grey-020: #d6e0e6;
$blue-grey-025: #f4f6f7;
$blue-grey-030: #b2c2c9;
$blue-grey-035: #e2e2e2;
$blue-grey-050: #eff3f5;
$blue-grey-075: #dde3e6;
$blue-grey-080: #2e3b40;
$blue-grey-100: #cfd8dc;
$blue-grey-200: #b0bec5;
$blue-grey-300: #8fa4af;
$blue-grey-400: #77909d;
$blue-grey-500: #5f7d8c;
// added from figma design system
$blue-grey-501: #76868d;
$blue-grey-600: #536e7b;
$blue-grey-700: #4b5f75;
// added from figma design system
$blue-grey-701: #48565d;
$blue-grey-800: #37474f;
$blue-grey-900: #253238;
$indigo-200: #9fa7dc;
$indigo-900: #191e80;
$turquoise-100: #98dad7;
$turquoise-300: #51b7b1;
$turquoise-400: #24a69f;
$turquoise-500: #1d9790;
$red-600: #e53935;
$amber-500: #ffc107;
$charcoal-grey: #36474f;
$gull-grey: #a1b0b8;
$admithub-grey: #f2f2f2;
$message-no-match: #f4f9fd;
$pale-green: #0fc65b;
$new-ui-primary: #01a59a;
$new-ui-primary-highlight: #03b6aa;
$new-ui-danger: #e34646;
$new-ui-danger-light: #fae3e5;
$new-ui-selected-light: #e3effa;
$warning-light: #fffff1;
$dark-purple: #620b3e;
$light-purple: #620b3e1a;
$opacity-50: rgba(255, 255, 255, 0.5);
$blue-20: #deebff;
$blue-90: #003e82;
$white: #ffffff;
$light-gray: #d6e0e6;
$dark-gray: #5e6b72;
$denim-blue: #1b71ce;
$danger-light: #ffdcdc;

// Messages
$message-incoming: #c9dced;
$message-incoming-border: #c9dced;
$message-incoming-color: #425468;
$archived-badge-color: #95a7b0;
$message-image-preview-border-color: #d6e0e6;
// Script lib
$line: $blue-grey-030;

// New design
$mainstay-blue-10: #f2f9ff;
$mainstay-blue-15: #d9ecfa;
$mainstay-blue-40: #6fadf0;
$mainstay-blue-60: #1b71ce;
$mainstay-blue-70: #0063d0;
$mainstay-blue-80: #0051aa;
$mainstay-dark-blue: #092e63;
$mainstay-dark-blue-80: #3a5882;
$mainstay-dark-blue-70: #536d92;
$mainstay-dark-blue-65: #5f779a;
$mainstay-dark-blue-50: #8497b1;
$mainstay-dark-blue-20: #ced5e0;
$mainstay-dark-blue-10: #e7ebf0;
$mainstay-dark-blue-05: #f2f4f7;
$mainstay-almost-black: #121619;
$mainstay-almost-black-80: #414547;
$mainstay-almost-black-30: #b8baba;
$mainstay-gray: #e8e9ea;
$mainstay-gray-20: #fafbfb;
$mainstay-gray-labels: #667085;
$mainstay-error-red: #cb271b;
$mainstay-error-50: #fdf3f3;
$mainstay-error-100: #fee4e2;
$mainstay-error-300: #fda29b;
$mainstay-error-600: #cb271b;
$mainstay-error-700: #a31208;
$mainstay-spark-red: #f23661;
$mainstay-electric-red: #f4456d;
$mainstay-dark-mint: #00c6a4;
$mainstay-dark-mint-w-opacity: rgba(0, 198, 164, 0.1);
$mainstay-dark-green: #08785d;
$mainstay-mint: #00ffd4;
$mainstay-teal: $secondary-teal;
$mainstay-cream-30: #fffefc;
$mainstay-text-default: #2e3b40;
$mainstay-light-green: #097f65;
$mainstay-gray-backgrounds: #f9fafb;
$mainstay-gray-lines: #d0d5dd;
$mainstay-gray-header: #1d2939;
$mainstay-gray-text: #475467;
$mainstay-success-50: #f6fef9;
$mainstay-success-100: #d1fadf;
$mainstay-success-500: #12b76a;
$mainstay-success-600: #039855;
$mainstay-success-700: #05603a;
$mainstay-focused-gray: #ced5e0;
$mainstay-warning-50: #fffcf5;
$mainstay-warning-100: #fff0c8;
$mainstay-warning-300: #fec84b;
$mainstay-warning-600: #dc6803;
$mainstay-warning-700: #b54708;
$mainstay-disabled-gray: #98a2b3;

// Expanded Insights Colors
$pumpkin-spice: #ea7405;
$forest-whisper: #487f4a;
$lavish-lilac: #c642db;
$azure-blast: #0063d0;
$blaze-burst: #e84717;
$royal-plum: #8a58ca;
$cerulean-splash: #20a2c1;
$ocean-spray: #189580;
$coral-crush: #ff5279;
$wine-wisp: #620b3e;
$slate-harbor: #667085;
$serene-sky: #4692e6;
$mauve-mirage: #a17ba7;
$misty-morning: #8497b1;
$amber-glow: #e67205;
$verdant-vibes: #5ca35f;
$pink-petal: #e76482;
$forest-fern: #1d9a19;
$emerald-essence: #32a491;
$rustic-charm: #d07053;
$elegant-orchid: #a883d8;

// TODO(sbdchd): remove all the unused colors

$theme-colors: (
  'primary': $admithub-primary,
  'danger': $admithub-orange,
  'incoming': $admithub-baby-blue,
  'forwarded': $admithub-green,
  'new-ui-primary': $new-ui-primary,
  'new-ui-primary-highlight': $new-ui-primary-highlight,
  'new-ui-selected-light': $new-ui-selected-light,
  'new-ui-danger': $new-ui-danger,
  'new-ui-danger-light': $new-ui-danger-light,
  'blue-grey-005': $blue-grey-005,
  'blue-grey-010': $blue-grey-010,
  'blue-grey-015': $blue-grey-015,
  'blue-grey-020': $blue-grey-020,
  'blue-grey-025': $blue-grey-025,
  'blue-grey-030': $blue-grey-030,
  'blue-grey-050': $blue-grey-050,
  'blue-grey-075': $blue-grey-075,
  'blue-grey-080': $blue-grey-080,
  'blue-grey-100': $blue-grey-100,
  'blue-grey-200': $blue-grey-200,
  'blue-grey-300': $blue-grey-300,
  'blue-grey-400': $blue-grey-400,
  'blue-grey-500': $blue-grey-500,
  'blue-grey-501': $blue-grey-501,
  'blue-grey-600': $blue-grey-600,
  'blue-grey-701': $blue-grey-701,
  'blue-grey-800': $blue-grey-800,
  'blue-grey-900': $blue-grey-900,
  'indigo-200': $indigo-200,
  'indigo-900': $indigo-900,
  'turquoise-100': $turquoise-100,
  'turquoise-300': $turquoise-300,
  'turquoise-400': $turquoise-400,
  'turquoise-500': $turquoise-500,
  'red-600': $red-600,
  'amber-500': $amber-500,
  'charcoal-grey': $charcoal-grey,
  'gull-grey': $gull-grey,
  'message-no-match': $message-no-match,
  'message-incoming': $message-incoming,
  'pale-green': $pale-green,
  'admithub-grey': $admithub-grey,
  'admithub-primary': $admithub-primary,
  'warning-light': $warning-light,
  'dark-purple': $dark-purple,
  'light-purple': $light-purple,
  'secondary-teal': $secondary-teal,
  'opacity-50': $opacity-50,
  'blue-20': $blue-20,
  'blue-90': $blue-90,
  'white': $white,
  'gray-150': $gray-150,
  'archived-badge-color': $archived-badge-color,
  'line': $line,
  'dark-gray': $dark-gray,
  'denim-blue': $denim-blue,
  'mainstay-blue-10': $mainstay-blue-10,
  'mainstay-blue-15': $mainstay-blue-15,
  'mainstay-blue-40': $mainstay-blue-40,
  'mainstay-blue-60': $mainstay-blue-60,
  'mainstay-blue-70': $mainstay-blue-70,
  'mainstay-blue-80': $mainstay-blue-80,
  'mainstay-dark-blue': $mainstay-dark-blue,
  'mainstay-dark-blue-80': $mainstay-dark-blue-80,
  'mainstay-dark-blue-70': $mainstay-dark-blue-70,
  'mainstay-dark-blue-65': $mainstay-dark-blue-65,
  'mainstay-dark-blue-50': $mainstay-dark-blue-50,
  'mainstay-dark-blue-20': $mainstay-dark-blue-20,
  'mainstay-dark-blue-10': $mainstay-dark-blue-10,
  'mainstay-dark-blue-05': $mainstay-dark-blue-05,
  'mainstay-almost-black': $mainstay-almost-black,
  'mainstay-almost-black-30': $mainstay-almost-black-30,
  'mainstay-almost-black-80': $mainstay-almost-black-80,
  'mainstay-dark-mint': $mainstay-dark-mint,
  'mainstay-dark-mint-w-opacity': $mainstay-dark-mint-w-opacity,
  'mainstay-dark-green': $mainstay-dark-green,
  'mainstay-mint': $mainstay-mint,
  'mainstay-teal': $mainstay-teal,
  'mainstay-cream-30': $mainstay-cream-30,
  'mainstay-gray-20': $mainstay-gray-20,
  'mainstay-error-50': $mainstay-error-50,
  'mainstay-error-100': $mainstay-error-100,
  'mainstay-error-300': $mainstay-error-300,
  'mainstay-error-600': $mainstay-error-600,
  'mainstay-error-700': $mainstay-error-700,
  'mainstay-error-red': $mainstay-error-red,
  'mainstay-spark-red': $mainstay-spark-red,
  'mainstay-focused-gray': $mainstay-focused-gray,
  'mainstay-text-default': $mainstay-text-default,
  'mainstay-light-green': $mainstay-light-green,
  'mainstay-gray-backgrounds': $mainstay-gray-backgrounds,
  'mainstay-gray-lines': $mainstay-gray-lines,
  'mainstay-gray-header': $mainstay-gray-header,
  'mainstay-gray-text': $mainstay-gray-text,
  'mainstay-success-50': $mainstay-success-50,
  'mainstay-success-100': $mainstay-success-100,
  'mainstay-success-500': $mainstay-success-500,
  'mainstay-success-600': $mainstay-success-600,
  'mainstay-success-700': $mainstay-success-700,
  'mainstay-warning-50': $mainstay-warning-50,
  'mainstay-warning-100': $mainstay-warning-100,
  'mainstay-warning-300': $mainstay-warning-300,
  'mainstay-warning-600': $mainstay-warning-600,
  'mainstay-warning-700': $mainstay-warning-700,
  'mainstay-disabled-gray': $mainstay-disabled-gray,
  'mainstay-gray-labels': $mainstay-gray-labels,
  'link-primary': $link-primary,
  'link-primary-hover': $link-primary-hover,
);

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';

// Overall Defaults
$body-color: $mainstay-dark-blue-80;
$default-icon-color: $mainstay-dark-blue-65;
$border: $border-width solid $border-color;

// Type
$font-family-sans-serif: 'Roboto', 'Helvetica Neue', Helvetica, Arial,
  sans-serif;
$font-family-calibre: 'Calibre', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-source-sans-pro: 'Source Sans Pro', 'Helvetica Neue', Helvetica,
  Arial, sans-serif;
$font-family-base: $font-family-source-sans-pro;
$font-size-md: ($font-size-base * 0.95);
$font-size-xs: ($font-size-base * 0.8);
$headings-font-weight: $font-weight-bold;

$h2-font-size: $font-size-base * 1.6;
$font-size-13: 0.8125rem;

// Inputs
$input-color: $mainstay-dark-blue;
$input-bg: $mainstay-dark-blue-10;
$input-bg-hover: $mainstay-dark-blue-20;
$input-border-color: transparent;
$input-border-width: 0;
$input-placeholder-color: rgba($input-color, 0.5);
$input-focus-color: $mainstay-dark-blue;
$input-focus-bg: $mainstay-dark-blue-20;
$input-focus-border-color: transparent;
$input-padding-x-lg: $input-padding-x;

// Panels
$panel-padding-x: 1rem;
$panel-padding-y: 0.5rem;
$panel-header-height: 3.5rem;
$panel-main-padding-x: ($panel-padding-x * 2);

// Sidebar
$sidebar-bg: $mainstay-cream-30;

$lg-radius: 30px;
$md-radius: 15px;

// Forms
$form-feedback-invalid-color: $new-ui-danger;

@mixin default-transition {
  @include transition(all 0.4s ease);
}

@mixin unstyled-link {
  &,
  &:visited,
  &:hover,
  &:active,
  &:focus,
  &:active:hover {
    color: inherit;
    text-decoration: none;
  }
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// Export sass variables as js variables. Types are added in `src/scss/scss.d.ts`. Add scss variables both here and there
// in order to use in .ts and .tsx files
:export {
  admithubPrimary: $admithub-primary;
  secondaryTeal: $secondary-teal;
  mainstayAlmostBlack: $mainstay-almost-black;
  mainstayDarkBlue: $mainstay-dark-blue;
  mainstayDarkBlue05: $mainstay-dark-blue-05;
  mainstayDarkBlue10: $mainstay-dark-blue-10;
  mainstayDarkBlue20: $mainstay-dark-blue-20;
  mainstayDarkBlue65: $mainstay-dark-blue-65;
  mainstayDarkBlue70: $mainstay-dark-blue-70;
  mainstayDarkBlue80: $mainstay-dark-blue-80;
  mainstayDarkMint: $mainstay-dark-mint;
  mainstayDarkMintWOpacity: $mainstay-dark-mint-w-opacity;
  mainstayDarkGreen: $mainstay-dark-green;
  mainstayElectricRed: $mainstay-electric-red;
  mainstayLightGreen: $mainstay-light-green;
  mainstayFocusedGray: $mainstay-focused-gray;
  mainstayBlue10: $mainstay-blue-10;
  mainstayBlue40: $mainstay-blue-40;
  mainstayBlue60: $mainstay-blue-60;
  mainstayBlue70: $mainstay-blue-70;
  mainstayBlue80: $mainstay-blue-80;
  mainstayBlue50: $mainstay-error-50;
  mainstayError300: $mainstay-error-300;
  mainstayError600: $mainstay-error-600;
  mainstayError700: $mainstay-error-700;
  mainstayGrayBackgrounds: $mainstay-gray-backgrounds;
  mainstayDisabledGray: $mainstay-disabled-gray;
  mainstayGrayLines: $mainstay-gray-lines;
  mainstayGrayHeader: $mainstay-gray-header;
  mainstayGrayText: $mainstay-gray-text;
  mainstayGrayLabels: $mainstay-gray-labels;
  mainstaySuccess50: $mainstay-success-50;
  mainstaySuccess500: $mainstay-success-500;
  mainstaySuccess600: $mainstay-success-600;
  mainstaySuccess700: $mainstay-success-700;
  mainstayWarning10: $mainstay-warning-50;
  mainstayWarning300: $mainstay-warning-300;
  mainstayWarning600: $mainstay-warning-600;
  mainstayWarning700: $mainstay-warning-700;
  newUIDanger: $new-ui-danger;
  newUIDangerLight: $new-ui-danger-light;
  blue20: $blue-20;
  mainstayTeal: $mainstay-teal;
  pumpkinSpice: $pumpkin-spice;
  forestWhisper: $forest-whisper;
  lavishLilac: $lavish-lilac;
  azureBlast: $azure-blast;
  blazeBurst: $blaze-burst;
  royalPlum: $royal-plum;
  ceruleanSplash: $cerulean-splash;
  oceanSpray: $ocean-spray;
  coralCrush: $coral-crush;
  wineWisp: $wine-wisp;
  slateHarbor: $slate-harbor;
  sereneSky: $serene-sky;
  mauveMirage: $mauve-mirage;
  mistyMorning: $misty-morning;
  amberGlow: $amber-glow;
  verdantVibes: $verdant-vibes;
  pinkPetal: $pink-petal;
  forestFern: $forest-fern;
  emeraldEssence: $emerald-essence;
  rusticCharm: $rustic-charm;
  elegantOrchid: $elegant-orchid;
  linkPrimary: $link-primary;
  linkPrimaryHover: $link-primary-hover;
}
