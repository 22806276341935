@import 'scss/_variables.scss';

.day-picker-input--disabled {
  background-color: #f2f4f7 !important;
  cursor: default !important;
  color: $mainstay-dark-blue-80 !important;
}

.day-picker-day-offset-input {
  width: 60px !important;
  margin: 0px 10px 0px 16px;
  transition: none !important;
}

.day-picker-day-offset-input--error-msg {
  position: absolute;
  margin-left: 16px;
}
