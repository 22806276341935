@import 'scss/_variables.scss';

.label {
  min-width: 100px;
  max-width: 100px;
}

.value {
  min-width: 200px;
  max-width: 600px;
}
