@import 'scss/_variables.scss';

.popout-menu {
  border-radius: 15px;
  min-width: 248px;
  background-color: $mainstay-almost-black-80;
  filter: drop-shadow(0px -12px 50px $mainstay-dark-blue-20);
  clip-path: inset(-100vw -100vw -100vw 0);
  overflow-x: hidden;

  ul {
    line-height: 1.8rem;
  }

  a {
    color: $mainstay-dark-blue-05;
  }

  a:hover {
    color: lighten($blue-grey-200, 20%);
    text-decoration: none;
  }
}
