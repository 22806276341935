.value-container {
  display: flex;
  flex-wrap: nowrap !important;
  align-items: center;
  justify-content: space-between;
}

.w-85 {
  max-width: 85%;
}
