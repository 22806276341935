@import 'scss/_variables.scss';

.btn-blue-grey-005 {
  background-color: $blue-grey-005;
  &:hover,
  &.active {
    background-color: $blue-grey-010;
  }
}

.btn.opacity-unset {
  opacity: 1 !important;
}
