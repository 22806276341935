@import 'scss/_variables.scss';
@import 'scss/_iconfont/iconfont_variables.scss';

.nested-drop-item {
  padding: 7px 10px 9px 12px;
  max-height: 40px;
  border: #d8dee8 1px solid;

  &:hover {
    background-color: $mainstay-dark-blue-10;
  }

  font-family: $font-family-base;
  font-size: 1rem;
  font-weight: 400;
  color: $mainstay-dark-blue;
}

button.szh-menu-button.nested-drop-button {
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  &:hover {
    background-color: #d6e0e6;
  }

  > span.action-button {
    &:focus {
      background-color: #d6e0e6;
      box-shadow: none !important;
      outline: -webkit-focus-ring-color auto 1px !important;
    }

    &:focus-visible {
      box-shadow: none !important;
      outline: -webkit-focus-ring-color auto 1px !important;
    }
  }
}

.menu-open-action-button {
  background-color: #d6e0e6 !important;
}

ul.szh-menu.nested-drop-menu {
  padding: 0;
  background-color: white;
  border: #d8dee8 1px solid;
  box-shadow: none;
  min-width: 180px;
  box-shadow: 0 0 0 0 hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);

  // Prevent double borders between bottom element in Menu and MenuItem itself in parent menu
  li:last-child > div {
    border-bottom: none;
  }
}

// Prevent double borders between bottom element in Menu and MenuItem itself in child menu
li.szh-menu__submenu > ul.szh-menu.nested-drop-menu {
  li:last-child {
    border-bottom: none;
  }
}

// Border radius should be 3px except for the corners that touch their element's trigger element
ul.szh-menu.nested-drop-menu.szh-menu--dir-right {
  border-radius: 0 3px 3px 3px;
}

ul.szh-menu.nested-drop-menu.szh-menu--dir-left {
  border-radius: 3px 0 3px 3px;
}

.szh-menu__item.nested-drop-item {
  background-color: transparent;
  padding: 7px 10px 9px 12px;
  max-height: 40px;
  // Items should only have border-bottom in order to prevent borders from getting doubled up with surrounding menus
  border-left: #d8dee8 0px solid;
  border-right: #d8dee8 0px solid;
  border-top: #d8dee8 0px solid;

  &:hover {
    background-color: $mainstay-dark-blue-10;
  }

  &:focus-visible {
    box-shadow: none !important;
    outline: -webkit-focus-ring-color auto 1px !important;
  }
}

// Disabled items should not have a hover state
.szh-menu__item--disabled.nested-drop-item {
  &:hover {
    background-color: transparent;
  }
}

// Prevent default arrow from rendering on SubMenu element
.szh-menu__submenu > .szh-menu__item::after {
  display: none;
}

.szh-menu__submenu > .szh-menu__item.nested-drop-item {
  padding-right: 8px;
}
