@import 'scss/_variables.scss';

a.dashboard-sidebar-item {
  padding: 0.2rem 0.5rem;
  color: $mainstay-dark-blue-70 !important;

  &.active,
  &:hover {
    background-color: $mainstay-dark-blue-05 !important;
    color: $mainstay-dark-blue !important;
  }
}

.dashboard-form-footer {
  display: flex;
}

.w-200px {
  width: 200px;
}

$form-item-height: 350px;

.chart-form-item {
  height: $form-item-height !important;
  border: 1px solid $mainstay-dark-blue-20;
  border-radius: 4px;
  background-color: white;
}

.add-chart-btn {
  height: $form-item-height !important;
  width: 100%;
  background: $mainstay-dark-blue-05;
  border: 1px dashed $mainstay-dark-blue-20 !important;
  > span {
    color: $mainstay-dark-blue-20;
  }
}

.mb-7rem {
  margin-bottom: 7rem;
}

.create-dashboard-icon {
  margin-left: -2px;
}
