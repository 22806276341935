@import '_variables.scss';

// Panel Styles
//
// Panel widths are not set by default,
// use bootstrap columns to set widths
//
// Panel
// > Panel Header
// >> Panel Header Content
// >>> Panel Title
// >>> Header Secondary
// > Panel Content
// > Panel Footer

.panel {
  // Note:
  // Does not need an explicit height becuase it's display:flex; and safari interprets this incorrectly.
  // Still needs max-height to force overflow
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: hidden;
  border-right: $border;

  // We override the default column padding so scrollable areas reach to the edgoe of the panel, eliminating scroll traps. Instead, padding is built in to sub-components
  padding: 0;

  &.panel-last {
    border-right: none;
  }
}
.panel-header {
  padding: 0 $panel-padding-x;
  width: 100%;
  z-index: 2;

  .panel-header-content {
    display: flex;
    position: relative;
    border-bottom: $border;
    height: $panel-header-height;
    align-items: center;
  }
  .header-secondary {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    // Temporary Expanding Search Bar
    // Move to component file
    // Will need refactoring for other instances
    .search-bar-expand {
      position: absolute;
      z-index: 2;
      top: 0.5rem;
      left: 0;
    }
  }
  .panel-title {
    span {
      display: block;
    }
  }
}
.panel-content {
  overflow-y: auto;
  &:not(.no-panel-content-padding) {
    padding: $panel-padding-y $panel-padding-x ($panel-padding-y * 5);
  }
}
.panel-footer {
  padding: 0 $panel-padding-x $panel-padding-y;
  z-index: 2;
}

$panel-content-padding: $panel-padding-y $panel-main-padding-x
  ($panel-padding-y * 7);

.panel-content-padding {
  padding: $panel-content-padding;
}

// Main Panel
.panel.panel-main {
  border-right: none;

  .panel-header {
    padding: 0 $panel-main-padding-x;
  }
  .panel-content {
    padding: $panel-content-padding;
  }
  .panel-footer {
    padding: 0 $panel-main-padding-x $panel-padding-y;
  }
}

// Placeholder for responsive panels
// Until we make mobile-filter menus
.placeholder-mobile-panels {
  @include media-breakpoint-down(sm) {
    flex-direction: column;

    .sidebar {
      max-height: 25vh;
      box-shadow: none;
      z-index: 2;
      width: 100%;
    }
    .panel-main {
      box-shadow: 0 -8px 12px 0 rgba(0, 0, 0, 0.15);
      z-index: 2;

      .panel-content {
        padding: $panel-padding-y $panel-padding-x ($panel-padding-y * 5);
      }
    }
  }
}
