@import 'scss/_variables.scss';
.w-144 {
  width: 144px;
}

.w-256 {
  width: 256px;
}

.light-green-text {
  color: $mainstay-light-green;
}

.create-user-form {
  .form-group {
    margin-right: 32px;
  }
  .role-select {
    margin-right: 32px;
  }
}
