/*
	Following Bootstrap Convention,
	Overriding form styles here instead of on individual components
*/

// Base Styling for all form elements
.form-control:not(.no-override) {
  background-color: $input-bg;

  &:hover {
    background-color: $input-bg-hover;
  }
  &:focus,
  &:focus:hover {
    background-color: $input-bg;
    box-shadow: none;
  }

  // "Outline" Variants
  &.outline {
    border: 1px solid $gray-400;
    background-color: transparent;

    &:hover {
      border-color: $gray-500;
    }
    &:focus,
    &:focus:hover {
      border-color: $gray-400;
      background-color: transparent;
    }
  }
  // "Reveal" Variants
  &.reveal {
    border: 1px solid transparent;
    background-color: transparent;

    &:hover {
      border-color: $gray-500;
    }
    &:focus,
    &:focus:hover {
      border-color: $gray-400;
      background-color: transparent;
    }
  }
}

select.form-control:not(.no-override) {
  &:disabled {
    color: rgba($input-color, 0.7);
  }
}

input.form-control:not(.no-override) {
  &:disabled,
  &:read-only {
    background-color: $input-bg;
    color: rgba($input-color, 1);
    cursor: not-allowed;
  }
}

input.form-control::placeholder:not(.no-override) {
  color: rgba($input-color, 0.7);
}

// Styling for ONLY inputs
input.form-control:not(.no-override),
textarea.form-control:not(.no-override),
.input-wrapper.form-control:not(.no-override) {
  transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  background-image: linear-gradient(
    to top,
    $admithub-primary 2px,
    rgba($input-bg, 0) 2px,
    rgba($input-bg, 0) 100%
  );
  background-size: 0;
  // Use % here to work around Safari bug
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-color: $input-bg;

  &::placeholder {
    opacity: 1;
  }

  &.is-invalid {
    box-shadow: none !important;
    color: $new-ui-danger;
    background-image: linear-gradient(
      to top,
      $form-feedback-invalid-color 2px,
      rgba($input-bg, 0) 2px,
      rgba($input-bg, 0) 100%
    );
    background-color: $new-ui-danger-light;

    &:focus,
    &:focus:hover {
      background-color: $new-ui-danger-light;
    }
  }
  &:focus,
  &:focus:hover {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-size: 100%;
    background-color: $input-bg;
  }
}

.labeled-text-area {
  padding-top: 20px !important;
  padding-left: 12px !important;
  background: inherit !important;
}

// Changes for Labeled variants
.form-group-labeled:not(.no-override) {
  position: relative;

  label {
    position: absolute;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
    left: $input-padding-x;
    top: calc(#{$input-padding-y} + 0.375rem);
    color: rgba($input-color, 0.7);
    font-size: $font-size-base;
  }
  &.has-value,
  &.active-label-input {
    label {
      font-size: $font-size-xs;
      top: calc(#{$input-padding-y} * 0.45);
    }
  }
  &.active-label-input {
    label {
      color: $admithub-primary;
    }
  }
  // Large Form Groups
  &.form-group-lg {
    label {
      font-size: $font-size-lg;
    }
    &.has-value,
    &.active-label-input {
      label {
        font-size: $font-size-xs;
        top: calc(#{$input-padding-y} * 0.7);
      }
    }
  }
  input.form-control,
  textarea.form-control {
    height: auto;
    padding-top: calc(#{$input-padding-y} + 0.8rem);

    &.active-label-input {
      label {
        color: $form-feedback-invalid-color;
      }
    }
  }
}

// Intended to be reusable component
.search-input:not(.no-override) {
  position: relative;
  background-color: $white;
  width: 100%;
  display: block;

  // Intentionally not using input-group here to take advantage of default input styling
  .search-input-prepend,
  .search-input-append {
    position: absolute;
    display: flex;
    height: 100%;
    z-index: 2;
    background-color: transparent;
    font-size: 1.2rem;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    top: 0;
  }
  .search-input-prepend {
    left: 0;
  }
  .search-input-append {
    right: 0;
  }
  input.form-control {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    z-index: 1;
    position: relative;
  }
  .ahicon {
    color: $gray-600;
  }
  .btn-icon,
  .btn-icon:hover {
    background-color: transparent;
  }
}
.input-group-text {
  background-color: $input-bg;
}

.primary-border-btn:not(.no-override) {
  background: $white;
  border: 3px solid $new-ui-primary;
  border-radius: 5px;
  color: $new-ui-primary;
  transition: all 0.3s linear;

  &:hover,
  &:active {
    border-color: $new-ui-primary-highlight;
    color: $new-ui-primary-highlight;
  }
}

input[type='number']::-webkit-inner-spin-button:not(.no-override),
input[type='number']::-webkit-outer-spin-button:not(.no-override) {
  -webkit-appearance: none;
  margin: 0;
}
