@import 'scss/_variables.scss';

.input-width {
  min-width: 150px;
  width: 300px;
}

.textarea-width {
  min-width: 200px;
  width: 400px;
}

.row-input-width {
  min-width: 220px;
  width: 240px;
}

.async-selector-width {
  min-width: 360px;
  width: 440px;
}

.list-url-path {
  word-break: break-all;
}

.border-bottom-dark {
  border-bottom: 2px solid $mainstay-text-default;
}

.editor-border {
  border: 1px solid $mainstay-dark-blue-10;
}

.field-caption {
  font-size: 13px;
  color: $dark-gray;
}

.select-height {
  .react-select__control {
    min-height: 50px;
  }
}

.h-70px {
  height: 72px;
}

.h-50px {
  height: 50px;
}

.h-20px {
  height: 20px;
}

.field-row {
  .form-group {
    margin-bottom: 0px;
  }
}

.move-down-2px {
  transform: translateY(2px);
}

.daypicker-align-left {
  left: auto !important;
  right: 0;
}

.DayPickerInput-Overlay {
  // Ensure overlay stays above page.
  z-index: 10;
}

.checkbox-dim {
  height: 16px;
  width: 16px;
}

.image-size {
  max-height: 120px;
  max-width: 400px;
  height: auto;
  width: auto;
}

.extra-bottom-padding {
  padding-bottom: 5rem;
}

.fancy-text-box:not(.shadow-border).textarea-style {
  border: none;
}

.textarea-style {
  display: flex;
  flex-flow: column wrap;
  min-width: 200px;
  width: 400px;
  border-radius: $border-radius;
  background-color: $mainstay-dark-blue-10;
  color: $mainstay-dark-blue-80;
  transition: all 0.2s ease-out;
  cursor: text;

  textarea {
    border: none;
    color: $mainstay-dark-blue-80;
    &:focus,
    &:focus-visible {
      border: none;
      outline: none;
    }
  }
}
