@import 'scss/_variables.scss';

$approved: #30ac35;
$not-approved: #eda318;
$no-answer: #de3333;

.understanding-approval-menu-component {
  .dot {
    margin-right: 10px;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: green;

    &.approved {
      background-color: $approved;
    }

    &.not-approved {
      background-color: $not-approved;
    }

    &.no-answer {
      background-color: $no-answer;
    }
  }
  .dotv2 {
    margin-right: 10px;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: green;

    &.approved {
      background-color: $approved;
    }

    &.not-approved {
      background-color: $not-approved;
    }

    &.no-answer {
      background-color: $no-answer;
    }
  }

  .menu-buttonv2 {
    border-color: $gray-400;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 10px;
    padding-right: 2px;
    border-radius: 2rem;

    .ahicon {
      font-size: 18pt;
      color: $gray-600;
    }
  }

  .menu-button {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border-color: $gray-400;
    padding: 5px 12px;
    border-radius: 2rem;
    font-size: 10pt;

    .ahicon {
      font-size: 18pt;
      color: $gray-600;
    }
  }

  &.wide .menu-button {
    min-width: 170px;
  }

  .popper {
    width: 220px;
  }

  .card-container {
    box-shadow: 1px 4px 6px $gray-300;
    border: none !important;
    outline: none !important;
    padding: 12px 9px !important;

    .card-title {
      text-transform: uppercase;
      font-size: 9pt;
      color: $gray-200;
    }

    .placeholder {
      font-size: 10pt;
    }

    .menu-actions {
      .check-container {
        width: 25px;
      }

      .menu-action {
        display: flex;
      }
    }
  }
}
