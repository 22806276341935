@import 'scss/_variables.scss';

.name {
  font-weight: 600;
  font-size: 21px;
}

.contact-row {
  margin-bottom: 10px;
  font-size: 1rem;
}

.daypicker-contact-panel-overlay-wrapper {
  position: relative;
  right: 115px;
}

.status-selector {
  width: 120px;
}

.daypicker-contact-panel-overlay {
  bottom: 2.25rem;
}

.daypicker-contact-panel-input {
  cursor: pointer !important;
  color: $mainstay-dark-blue !important;
}

.country-calling-code-dropdown {
  width: 153px !important;
}

.intl-phone-container > input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.min-height-50px {
  min-height: 50px !important;
}

.collapsible-section-content {
  padding-left: 0.7rem;
}

.contact-status-badge {
  border-radius: 3px;
  font-weight: 600;
  padding: 0px 5px 2px;
  line-height: 11px;
  padding-top: 1px;
  span {
    text-transform: uppercase;
    font-size: 11px !important;
    white-space: nowrap;
  }
}

.min-width-120px {
  min-width: 120px !important;
}

.align-tooltip-section-level {
  margin-top: 4px;
}

.align-warning-icon {
  margin-bottom: 4px;
}

.align-add-icon {
  margin-top: 3px;
}

.align-tooltip-field-level {
  display: inline;
  position: relative;
}

.align-tooltip-icon {
  position: absolute;
  margin-top: 1px;
  margin-left: -2px;
}

.align-lozenge {
  margin-top: 5px;
}

.expand-labels-link {
  .ahicon {
    display: inline-block;
    transform: translateY(4px) !important;
    width: fit-content !important;
  }
}

.conversation-summary-btn-container {
  width: 225px;
}
