// Replacing ball spinner with ring spinner

@import 'scss/_variables.scss';

.ring-spinner {
  display: inline-block;
  position: relative;

  // Control the overall diameter of the circle here
  // Can be in px, rem, or %
  width: 30px;
  height: 30px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid;
    border-color: $mainstay-dark-blue $mainstay-dark-blue transparent
      transparent;
    animation: ring-spin 1.2s cubic-bezier(0.57, 0.05, 0.58, 0.94) infinite;
  }
  div:nth-child(1) {
    animation-delay: -0.25s;
  }
  div:nth-child(2) {
    animation-delay: -0.17s;
  }
  div:nth-child(3) {
    animation-delay: -0.1s;
  }
}

.ring-spinner-small {
  width: 20px;
  height: 20px;
}

.ring-spinner-big {
  width: 40px;
  height: 40px;
}

@keyframes ring-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
