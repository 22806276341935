@import 'scss/_variables.scss';
.node-subtree,
.root {
  .top {
    border-left: 1px solid $line;
  }
  .node {
    min-width: 500px;
    max-width: 500px;
    img {
      max-width: 330px;
    }
  }
  .middle {
    height: 1px;
    border-bottom: 1px solid $line;
  }

  .bottom {
    border-left: 1px solid $line;
  }

  .vertical-line {
    border-left: 1px solid $line;
  }
  .line-container {
    display: flex;
  }
}

.root > .vertical-node:first-of-type > div > .vertical-line {
  visibility: hidden;
}

.node-content {
  border-left: 1px solid $line;
}

.node-subtree.last > .node-parent .bottom,
.node-subtree.last > .node-content {
  border-left: 0;
}

.root {
  padding: 1rem;
}

.root > .node-subtree:first-of-type {
  > .node-content {
    border-left: 0px;
  }
  > .vertical-node {
    display: none;
  }
}

.root > .node-subtree:first-of-type > .node-content {
  border-left: 0px;
}
