@import '_variables.scss';

// Default Sidebar Panel
// .sidebar class should be added to a .panel

$sidebar-width: 240px;
$sidebar-item-x: 0.8rem;

.sidebar {
  background-color: $sidebar-bg;
  color: $gray-700;
  border-right: 1px solid $mainstay-gray;
  font-size: $font-size-md;
  flex: none;

  &.mainstay {
    color: $mainstay-dark-blue-70;

    .sidebar-section {
      padding-top: 0.4rem;
      padding-bottom: 1.2rem;
    }

    .panel-header {
      padding: 0 10px 0 30px !important;

      .panel-header-content {
        height: auto !important;
        border: 0 !important;
      }

      .panel-title {
        padding: 0 !important;
      }
    }

    .panel-title {
      padding-left: 1.25rem;
      margin-bottom: 0.375rem;
      margin-top: 2.188rem;
      text-transform: uppercase !important;
      color: $mainstay-dark-blue-70 !important;
      letter-spacing: 0.06em;
    }

    .panel-content {
      padding: 0 0.625rem 1.875rem !important;
    }

    h6 {
      color: $mainstay-dark-blue-70;
    }

    .topic-item {
      width: auto;
      border-radius: 15px;
      color: $mainstay-dark-blue-70;
      margin-bottom: 0.125rem;
      margin-left: 0.625rem;
      padding: 0.2rem 0.625rem;

      &:hover,
      &.active {
        background-color: $mainstay-dark-blue-05;
        color: $mainstay-dark-blue;
      }
    }
  }

  .expand-link {
    .ahicon {
      font-size: 21px;
      vertical-align: -0.12rem;
    }
  }

  a.header-item {
    display: flex;
    width: 100%;
    color: inherit;
    text-decoration: none;
    font-size: 1.1rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-bottom: 0.375rem;
    padding: 0.2rem 0.625rem;
    &.active {
      font-weight: bold;
      background-color: $mainstay-dark-blue-05;
      color: $mainstay-dark-blue;
      border-radius: 15px;
    }
  }

  .topic-item {
    display: flex;
    justify-content: space-between;
    position: relative;
    border-radius: $border-radius;
    align-items: center;
    width: 100%;
    color: inherit;
    text-decoration: none;
    padding: 0.2rem $sidebar-item-x;
    height: 30px;
    .p-absolute {
      position: absolute;
      right: 0px;
      top: 0px;
    }
    .menu-btn {
      padding-top: 4px !important;
    }
    .list-group-item {
      padding: 6px 12px !important;
    }
    .popper {
      top: 10px !important;
    }
  }
  .form-control,
  .input-group-text {
    background-color: $blue-grey-075;
  }

  .topic-dot {
    width: 12px;
    height: 12px;
    margin-right: 0.6rem;
  }
}

.sidebar.sidebar-width {
  width: $sidebar-width;
}
