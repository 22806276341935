@import 'scss/_variables.scss';

.fancy-text-box {
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  border-radius: $border-radius;
  background-color: $white;
  transition: all 0.2s ease-out;
  border: 1px solid transparent;
  cursor: text;

  &.shadow-border {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  }

  &:not(.shadow-border) {
    border: 1px solid $gray-300;
  }

  &.fancy-webchat {
    border: 0 !important;
  }

  .fancy-before,
  .fancy-after {
    background-color: $white;
    padding: 0.2rem;
    border-radius: $border-radius;
    flex: 1 0 0;
    .icon-send {
      cursor: pointer;
    }
  }

  .fancy-after {
    align-self: flex-end;
  }
  .content-wrapper {
    display: block;
    width: 100%;

    textarea.form-control {
      padding: 1rem;
      background-color: $white;
      background-image: none;
    }
  }
  &.active-input {
    border-color: $admithub-primary;
  }
  &.is-invalid {
    background-color: $new-ui-danger-light;
  }
  &.is-invalid.active-input {
    border-color: $new-ui-danger;
  }
}

.read-only-input {
  background-color: #f2f4f7 !important;
  cursor: default !important;
  color: #3a5882 !important;
}
