@import 'scss/_variables.scss';

input[type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background-color: white;
  border: 1px solid #3a5882;
  border-radius: 10px;
  margin-right: 0px;
}

input[type='radio']:checked {
  box-shadow: 0 0 0 3px inset white;
  display: inline-block;
  content: '';
  background-color: #3a5882;
}
