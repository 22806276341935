@import 'scss/_variables.scss';

span.input--error {
  position: absolute;
  bottom: -6px;
  padding-left: 3px;
}

.header-w-100 {
  width: 100px !important;
}

.max-h-unset {
  max-height: none !important;
}

.ahicon-reset {
  display: inline-block;
  transform: translateY(3px) !important;
}

.reference-icon-up {
  transform: translateY(-3px) !important;
}
