@import 'scss/_variables.scss';

$modal-body-width: 800px;

.custom-download-modal {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 33%);
  }
  .custom-download-body {
    max-height: calc(
      640px - 74px - 90px - 2rem
    ); /* 95% of viewport height minus header and footer */
    overflow-y: scroll;
    min-width: $modal-body-width !important;
  }

  .field-name {
    max-width: calc(
      #{$modal-body-width}/ 3
    ); // there are 3 entries for each row
  }

  .checkbox-container-sm {
    min-width: 16px;
  }

  .scroll-shadow {
    box-shadow: inset 0px -11px 8px -10px #ccc !important;
    z-index: 5;
  }

  .submit-btn {
    .ring-spinner > div {
      border-color: white white transparent transparent;
    }
  }

  .help-icon {
    position: relative;
    top: 2px;
    font-size: 16px !important;
  }

  .history-count-select {
    max-width: 60px;
  }

  .history-radio {
    svg {
      fill: $mainstay-dark-blue !important;
    }
  }
}
