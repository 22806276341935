.mark-for-knowledge-review-modal-notes {
  width: 100%;

  textarea {
    margin-top: 0.2rem;
    width: 100% !important;
    height: 87px !important;
  }
}

.mark-for-knowledge-review-modal-rm-button {
  background: none;
  border: none !important;
  color: #84969c;
}
