// General Master Overrides

@import 'scss/_variables.scss';

// We manually set the width for the branded applicant conversations popover.
.popover.no-max-width {
  max-width: unset;
}

.table {
  color: $mainstay-dark-blue-80;
}

// Type
h1,
h2,
h3,
h4,
h5,
h6 {
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Toastify__toast-container--top-center {
  margin-left: 0;
  transform: translateX(-50%);
}

.Toastify__toast-container {
  width: auto;
  .Toastify__toast-body {
    padding: 0 0.5rem;
  }
}

.modal-content {
  left: 50%;
  transform: translateX(-50%);
  min-width: fit-content;
}

// React Select
.react-select__control {
  color: $input-color;
  border-color: transparent !important;
  transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  background-image: linear-gradient(
    to top,
    $admithub-primary 2px,
    rgba(237, 238, 238, 0) 2px,
    rgba(237, 238, 238, 0) 100%
  );
  background-size: 0;
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-color: $input-bg !important;
  cursor: pointer;

  &:hover {
    background-color: $input-bg-hover !important;
  }
}

.form-control.is-invalid .react-select__control {
  box-shadow: none !important;
  background-image: linear-gradient(
    to top,
    #e34646 2px,
    rgba(237, 238, 238, 0) 2px,
    rgba(237, 238, 238, 0) 100%
  );
  background-color: $new-ui-danger-light !important;
}

.react-select__control--is-focused {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-size: 100% !important;
  background-color: $input-bg;
  border-color: none !important;
  box-shadow: none !important;

  &:hover {
    background-color: $input-bg !important;
  }
}

.react-select__single-value {
  color: $mainstay-dark-blue !important;
}

.react-select__multi-value {
  border: 1px solid $blue-grey-600 !important;
  border-radius: 12px !important;
}

.react-select__multi-value__remove {
  border-radius: 0 12px 12px 0 !important;
  cursor: pointer !important;
}

.react-select__placeholder:not(.no-override) {
  color: $input-placeholder-color;
}

.react-select__menu,
.react-select__menu-list {
  background-color: transparent;
}

.react-select__option {
  background-color: white;
  color: $mainstay-dark-blue !important;
  cursor: pointer;

  &:hover:not(.react-select__option--is-selected):not(:disabled) {
    background-color: $mainstay-dark-blue-10 !important;
  }

  &:active:not(.react-select__option--is-selected):not(:disabled) {
    background-color: transparent !important;
  }

  &:focus:not(:hover):not(:disabled) {
    background-color: white !important;
  }
}

.react-select__option--is-selected:not(:disabled) {
  background-color: $link-primary !important;
  color: white !important;
  &:focus {
    background-color: $mainstay-dark-blue-10 !important;
    color: $mainstay-dark-blue !important;
  }
  &:hover {
    background-color: $mainstay-dark-blue-10 !important;
    color: $mainstay-dark-blue !important;
  }
}

.react-select__option--is-focused:not(.react-select__option--is-selected):not(:disabled) {
  background-color: transparent;
}

.react-select--error {
  span {
    color: $new-ui-danger !important;
  }

  .react-select__control {
    path {
      fill: $new-ui-danger !important;
    }
  }
}
