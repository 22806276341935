@import 'scss/_variables.scss';

$image-size: 350px;

.react-crop-wrapper {
  height: $image-size;
  width: $image-size;
  background: #c4c4c4;
  border-radius: $border-radius;
}

// Override ReactCrop to prevent image selection and ensure image stays within
// cropper boundaries.
.ReactCrop__image {
  pointer-events: none;
  user-select: none;
  max-height: $image-size;
  max-width: $image-size;
}
