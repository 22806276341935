@import '~emoji-mart/css/emoji-mart';

.emoji-mart-category .emoji-mart-emoji span,
.emoji-mart-anchor {
  cursor: pointer;
}

.emoji-mart-preview-emoji {
  display: none;
}
