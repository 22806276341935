@import 'scss/_variables.scss';

.closable-image {
  display: block;
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 5px;
  margin-left: 1rem;

  .ahicon {
    position: absolute;
    color: $default-icon-color;
    font-size: 1.6rem;
    top: -0.2em;
    right: -0.2em;
    z-index: 2;
    cursor: pointer;
    background-color: $white;
    border-radius: 50%;
  }
}
