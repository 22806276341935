.contact-feedback-table {
  border-bottom: none;

  thead th {
    border-top: none;

    &:nth-child(1) {
      width: 5%;
    }

    &:nth-child(2) {
      width: 30%;
    }

    &:nth-child(3) {
      width: 30%;
    }

    &:nth-child(4) {
      width: 25%;
    }

    &:nth-child(5) {
      width: 10%;
    }
  }
}
