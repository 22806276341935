@import '_variables.scss';

.main-container {
  background: $mainstay-gray-20;
  max-height: 100vh;
  overflow: hidden;
}

.settings-menu-link {
  color: $mainstay-cream-30 !important;

  &:hover,
  &:active {
    color: $mainstay-cream-30 !important;
  }
}

// Typography
h1 {
  font-size: 2.4375rem;
  line-height: 113%;
  font-weight: bold;
}

h2 {
  font-size: 1.953rem;
  line-height: 140%;
  font-weight: bold;
}

h3 {
  font-size: 1.563rem;
  line-height: 140%;
  font-weight: 600;
}

h4 {
  font-size: 1.25rem;
  line-height: 140%;
  font-weight: 600;
}

h5 {
  font-size: 1rem;
  line-height: 140%;
  font-weight: 600;
}

h6 {
  font-size: 0.8rem;
  line-height: 125%;
  font-weight: 600;
}

a,
p {
  font-weight: normal;
  font-size: 1rem;
  line-height: 140%;

  &.large {
    font-size: 1.25rem;
  }

  &.caption {
    font-size: 0.8rem;
  }

  &.meta {
    letter-spacing: 0.04rem;
    font-size: 0.8rem;
    line-height: 125%;
    font-weight: bold;
  }
}

.caption {
  font-size: 0.8rem;
}

.meta {
  letter-spacing: 0.04rem;
  font-size: 0.8rem;
  font-weight: bold;
}
