@import 'scss/_variables.scss';

.content-card-height {
  height: 52px;
}

.content-card {
  @extend .content-card-height;
  border-radius: 4px;
  border: 1px solid #d8dee8;
  background: #fff;
}

.mid-ellipsis-link {
  text-decoration: none !important;
  border-bottom: 1px solid transparent;
  width: fit-content !important;
  max-width: 220px;
  line-height: 14px;
  padding-top: 2.5px;
  &:hover {
    border-bottom: 1px solid #074a3a;
  }
}

.view-more-links-dropdown {
  cursor: pointer;
  line-height: 20px;
  border-radius: 3px;
  padding: 0px 8px;
  .caret-icon {
    transform: scale(1.15) rotate(90deg) !important;
  }
}

.view-more-links-dropdown--active {
  background-color: $mainstay-dark-blue-20;
}

.popper-links-container {
  width: 250px;
  background: white;
  border: 1px solid $mainstay-dark-blue-20 !important;
  border-radius: 4px;
  padding: 8px 0px;
  .mid-ellipsis-link {
    margin: 0px 12px !important;
    padding-bottom: 0px;
    transform: translateY(-2px);
    &:hover {
      border-bottom: 1px solid #074a3a;
    }
  }
}
