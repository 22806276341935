@import 'scss/_variables.scss';

.chip {
  display: inline-flex;
  width: auto;
  max-width: 100%;
  padding: 0.25rem 0rem 0.25rem 0.75rem;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.chip-pencil {
  font-size: 16px !important;
}

.chip-button {
  margin-left: 4px;
  float: right;
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  line-height: 1 !important;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  padding: 0 !important;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  span {
    color: $mainstay-dark-blue-65;
  }
}
