@import 'scss/_variables.scss';

.important-topics-table {
  border-bottom: none;

  tr.expanded {
    td {
      padding-bottom: 0 !important;
    }
  }

  thead th {
    border-top: none;

    &:nth-child(1) {
      width: 20%;
    }

    &:nth-child(2) {
      width: 5%;
    }

    &:nth-child(3) {
      width: 75%;
    }
  }

  tbody td {
    border-top: none;
    border-bottom: none;
  }
}

.important-subtopics-table {
  td {
    padding: 0 !important;

    &.subtopics-slider-wrapper {
      padding: 0 0 0 5rem !important;
    }
  }

  tbody td {
    border-top: none;

    &:nth-child(1) {
      width: 20%;
    }

    &:nth-child(2) {
      width: 5%;
    }

    &:nth-child(3) {
      width: 75%;
    }
  }
}

.knowledge-insight-percentage-label {
  width: 30px;
}

.subtopic-progress-bar {
  width: calc(100% - 20px);
  margin-left: 20px;
}
