@import '_variables.scss';
@font-face {
  font-family: 'Calibre';
  src: url('../assets/fonts/calibre-web-regular.woff') format('woff'),
    url('../assets/fonts/calibre-web-regular.eot') format('eot'),
    url('../assets/fonts/calibre-web-regular.woff2') format('woff2'),
    url('../assets/fonts/calibre-web-semibold.woff') format('woff'),
    url('../assets/fonts/calibre-web-semibold.eot') format('eot'),
    url('../assets/fonts/calibre-web-semibold.woff2') format('woff2'),
    url('../assets/fonts/calibre-web-bold.woff') format('woff'),
    url('../assets/fonts/calibre-web-bold.eot') format('eot'),
    url('../assets/fonts/calibre-web-bold.woff2') format('woff2');
}

html,
body,
#root {
  height: 100%;
}

// overrides to prevent overflow issue causing horizontal scrollbars to show
.rc-time-picker-panel-select li {
  padding-left: 0.5rem !important;
  width: unset !important;
}

.DayPickerInput-Container {
  input {
    border: 0;
  }
}

.DayPickerInput-Overlay {
  // Ensure overlay stays above page.
  z-index: 10;
}

// HACK(chdsbd): Attempt to square up ellipses into circles.
// I think the main problem is the use of table-based layouts in the component.
.DayPicker-Month {
  min-width: 281px;
}

// Icon Font
@import '_iconfont/iconfont_variables.scss';
@import '_iconfont/iconfont.scss';

@import 'bootstrap/scss/bootstrap.scss';
@import '_forms.scss';
@import '_overrides.scss';
@import '_panels.scss';
@import '_sidebar.scss';
@import '_tables.scss';
@import '_utils.scss';
@import '_new_design.scss';

// set the primary color used by loaders.scss
$primary-color: theme-color('primary');
@import '_loader.scss';
