@import 'scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.checkbox-container {
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 4px;

  .check {
    position: relative;
    left: 20%;
    bottom: 20%;
  }
}

.checkbox-container-sm {
  margin-bottom: 1px;
  margin-right: 0.125rem;
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: 4px;

  .check {
    position: relative;
    left: 13%;
    bottom: 36%;
  }
}

.checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 0.5rem;
  position: absolute;
  left: 0px;

  &:not(:checked) {
    border: 1px solid $mainstay-gray-text;
  }

  &:disabled {
    border: 1px solid $mainstay-disabled-gray !important;
  }

  &:not(:disabled) {
    cursor: pointer;
  }
}

.checkbox:hover {
  border: 1px solid $mainstay-dark-blue;
}

.checkbox:focus {
  outline: 0;
  border: 0;
  box-shadow: 0px 0px 0px 2px $mainstay-dark-blue;
}

.checkbox:focus-visible {
  outline: 0;
  border: 0;
  box-shadow: 0px 0px 0px 2px $mainstay-dark-blue;
}

.checked {
  border-radius: 4px;
  background-color: $mainstay-dark-blue;
}

.checked:hover {
  border: 0;
  background-color: $mainstay-dark-blue-65;
}

.checkbox-container:hover .checked {
  border: 0;
  background-color: $mainstay-dark-blue-65;
}

.bg-disabled {
  background: $mainstay-gray-backgrounds !important;
}
