@import 'scss/_variables.scss';

.counselors-multi-select-container {
  .react-select__multi-value {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .react-select-labeled {
    width: 100%;
  }

  .react-select__control {
    height: 50px;
  }

  .react-select__input {
    margin-top: 15px;
  }
}

.selected-counselors-container {
  max-width: 425px;
}
