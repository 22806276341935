@import 'scss/_variables.scss';

.react-select__option--is-selected:not(:disabled) {
  *.audience-option {
    color: white !important;
  }

  &:hover {
    *.audience-option {
      color: $link-primary !important;
    }
  }
}
