@import 'scss/_variables.scss';

$modal-header-plus-footer-height: 162px;
$modal-max-height: 590px;

.modal-80vh {
  height: 80vh !important;
}

.modal-max-h {
  max-height: $modal-max-height !important;
}

@media (min-width: 992px) {
  .modal-xl {
    &.modal-dialog {
      max-width: 1200px;
      width: 80%;
    }
    .modal-content {
      height: 80vh;
    }
  }
}

.modal-content-max-h {
  max-height: calc(
    #{$modal-max-height} - #{$modal-header-plus-footer-height}
  ) !important;
}

.modal-content {
  border-radius: 0.5rem !important;
}

.small-modal-width {
  width: 384px !important;
}

.medium-modal-width {
  width: 384px !important;
}

.modal-body {
  padding: 1.5rem 1.5rem 0rem 1.5rem !important;
  width: 100% !important;
}

.min-h-450 {
  min-height: 450px;
}

.ms-modal-header {
  font-family: Source Sans Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  color: $mainstay-dark-blue;
}
