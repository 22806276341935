@import 'scss/_variables.scss';

.knowledge-review-table {
  border-bottom: none;

  thead th {
    border-top: none;

    &:nth-child(1) {
      width: 30%;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(3) {
      width: 30%;
    }
    &:nth-child(4) {
      width: 10%;
    }
  }

  &.bot-could-not-answer,
  &.needs-personalized-answer,
  &.needs-interactive-answer {
    thead th {
      border-top: none;

      &:nth-child(1) {
        width: 5%;
      }
      &:nth-child(2) {
        width: 80%;
      }
      &:nth-child(3) {
        width: 15%;
      }
    }
  }

  &.answer-incorrect {
    thead th {
      border-top: none;

      &:nth-child(1) {
        width: 5%;
      }
      &:nth-child(2) {
        width: 30%;
      }
      &:nth-child(3) {
        width: 30%;
      }
      &:nth-child(4) {
        width: 20%;
      }
      &:nth-child(5) {
        width: 15%;
      }
    }
  }

  tbody td {
    border-bottom: none;
  }
}

.knowledge-review-item-toast {
  cursor: default;
  background-color: #000;
  width: 380px;
}

// CSS helper to show nested `child` element when `parent` is hovered
.hover-show-nested-parent:not(:hover) {
  .hover-show-nested-child {
    visibility: hidden !important;
  }
}

.conversation-message-relative-date {
  color: $mainstay-dark-blue-80;
  /* Mainstay Body/Paragraph */
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
}
.conversation-message-link {
  color: $secondary-teal;
  /* Mainstay Body/Paragraph */
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  word-break: break-word;
  /* 24px */
}

.matched-understanding-title {
  color: mainstay-dark-blue-80, $mainstay-dark-blue-80;
  /* Mainstay Header/H3 */
  font-family: Source Sans Pro;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 128%;
  /* 32px */
}

.truncate-title {
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
