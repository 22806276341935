// Version 2.8
$icomoon-font-family: 'admithub-icon-2-8' !default;
$icomoon-font-path: 'fonts' !default;

$icon-Logo-Inverted-Lockup: '\e900';
$icon-Logo-Inverted-Only: '\e901';
$icon-Logo-Full: '\e907';
$icon-Logo-Text-Only: '\e90a';
$icon-admithub-logo: '\e902';
$icon-bot: '\e903';
$icon-menu: '\e910';
$icon-dashboard: '\e904';
$icon-conversation: '\e8af';
$icon-knowledge-base: '\e905';
$icon-campaign: '\e906';
$icon-people: '\e7fb';
$icon-search: '\e8b6';
$icon-expand_less: '\e5ce';
$icon-double-arrow-left: '\e90e';
$icon-expand_more: '\e5cf';
$icon-navigate_before: '\e408';
$icon-navigate_next: '\e409';
$icon-arrow_drop_down: '\e5c5';
$icon-check: '\e5ca';
$icon-close: '\e5cd';
$icon-add: '\e145';
$icon-arrow_upward: '\e5d8';
$icon-arrow_downward: '\e5db';
$icon-arrow_forward: '\e5c8';
$icon-arrow_back: '\e5c4';
$icon-arrow_drop_up: '\e5c7';
$icon-chat_bubble: '\e0ca';
$icon-chat_bubble_outline: '\e0cb';
$icon-comment: '\e0b9';
$icon-cached: '\e86a';
$icon-phone: '\e0cd';
$icon-school: '\e909';
$icon-home: '\e88a';
$icon-open_in_new: '\e89e';
$icon-mobile_phone: '\e324';
$icon-visibility: '\e8f4';
$icon-reply: '\e15e';
$icon-sort: '\e164';
$icon-swap_vert: '\e8d5';
$icon-thumb_down: '\e8db';
$icon-thumb_up: '\e8dc';
$icon-thumbs_up_down: '\e8dd';
$icon-tv: '\e333';
$icon-verified_user: '\e8e8';
$icon-check_box: '\e834';
$icon-check_box_outline_blank: '\e835';
$icon-do_not_disturb: '\e611';
$icon-error: '\e000';
$icon-calendar_day: '\e24f';
$icon-cancel: '\e911';
$icon-highlight_off: '\e888';
$icon-file: '\e24d';
$icon-laptop: '\e31f';
$icon-indeterminate_check_box: '\e90b';
$icon-radio_button_checked: '\e90c';
$icon-radio_button_unchecked: '\e90d';
$icon-Toggle-off: '\e92c';
$icon-Toggle-on: '\e92d';
$icon-lock_open: '\e90f';
$icon-lock: '\e918';
$icon-more_horiz: '\e5d3';
$icon-more_vert: '\e5d4';
$icon-sentiment_very_satisfied: '\e815';
$icon-tag_faces: '\e420';
$icon-sentiment_satisfied: '\e813';
$icon-sentiment_neutral: '\e812';
$icon-sentiment_dissatisfied: '\e811';
$icon-sentiment_very_dissatisfied: '\e814';
$icon-settings: '\e8b8';
$icon-account_box: '\e851';
$icon-account_circle: '\e912';
$icon-person: '\e913';
$icon-new_releases: '\e914';
$icon-bookmark-selected: '\e8e6';
$icon-bookmark-empty: '\e8e7';
$icon-device_hub: '\e335';
$icon-send: '\e163';
$icon-add_circle: '\e915';
$icon-add_circle_outline: '\e916';
$icon-remove_circle: '\e917';
$icon-remove_circle_outline: '\e919';
$icon-image: '\e91a';
$icon-link_off: '\e91b';
$icon-link: '\e91c';
$icon-check_circle: '\e91d';
$icon-check_circle_outline: '\e91e';
$icon-help: '\e91f';
$icon-help_outline: '\e922';
$icon-info: '\e88e';
$icon-info_outline: '\e88f';
$icon-edit: '\e92e';
$icon-flag: '\e92f';
$icon-outlined-flag: '\e930';
$icon-question-summary: '\e931';
$icon-question: '\e932';
$icon-answer: '\e933';
$icon-delete: '\e920';
$icon-Stats: '\e934';
$icon-Book: '\e935';
$icon-print: '\e8ad';
$icon-mail-outline: '\e0e1';
$icon-infinite: '\e92a';
$icon-rss: '\e921';
$icon-youtube: '\e923';
$icon-pocket: '\e924';
$icon-medium: '\e925';
$icon-github: '\e926';
$icon-instagram: '\e927';
$icon-facebook: '\e928';
$icon-linkedin: '\e929';
$icon-twitter: '\e941';
$icon-messenger: '\e92b';
$icon-file_upload: '\e936';
$icon-file_download: '\e937';
$icon-get_app: '\e937';
$icon-cloud_upload: '\e938';
$icon-backup: '\e938';
$icon-cloud_download: '\e939';
$icon-file_download_done: '\e93a';
$icon-person_add: '\e93b';
$icon-drag_indicator: '\e93c';
$icon-drag_handle: '\e93d';
$icon-translate: '\e93e';
$icon-pin: '\e93f';
$icon-forward: '\e15f';
$icon-copy: '\e940';
$icon-label: '\e908';
$icon-label_off: '\e942';
$icon-archive: '\e943';
$icon-collection: '\e944';
$icon-trending_up: '\e946';
$icon-visibility_off: '\e945';
$icon-historyrestore: '\e947';
$icon-notes: '\e948';
$icon-multiple-choice: '\e949';
$icon-filter: '\e94a';
$icon-minimize: '\e94b';
$icon-pause: '\e94c';
$icon-reset: '\e94d';
$icon-attribute: '\e94e';
$icon-contacts-inverse: '\e94f';
$icon-script-inverse: '\e950';
$icon-campaign-inverse: '\e951';
$icon-knowledge-base-inverse: '\e952';
$icon-attribute-inverse: '\e953';
