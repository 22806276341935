$transition-time: 300ms;
$sidebar-width: 15rem;

.ms-page-content {
  padding: 0.5rem 3.5rem 0rem 3.5rem;
  width: 100%;
  overflow-y: auto;
  transition: all $transition-time ease;
}

.ms-page-content--expanded {
  padding: 0.5rem 2rem 0rem 2rem;
}

.mainstay-sidebar--expanded {
  transform: translateX(0rem) !important;
  transition: none !important;
}

.sidebar-transition-container-enter,
.sidebar-transition-container-exit-done {
  width: 0px;
  > .mainstay-sidebar {
    transform: translateX(-#{$sidebar-width});
    transition: transform $transition-time ease-out;
  }
}
.sidebar-transition-container-enter-active {
  width: $sidebar-width;
  transition: width $transition-time ease-out;
  > .mainstay-sidebar {
    transform: translateX(0rem);
    transition: transform $transition-time ease-out;
  }
}
.sidebar-transition-container-exit,
.sidebar-transition-container-enter-done {
  width: $sidebar-width;
  > .mainstay-sidebar {
    transform: translateX(0rem);
  }
}
.sidebar-transition-container-exit-active {
  width: 0px;
  transition: width $transition-time ease-out;
  animation-fill-mode: forwards;
  > .mainstay-sidebar {
    transform: translateX(-#{$sidebar-width});
    transition: transform $transition-time ease-out;
  }
}

$button-expanded-position: 12.5rem;
$button-transition-delay: 40ms;

.sidebar-btn {
  top: 0px;
  left: 0px;
  height: 24px;
  width: 24px;
  z-index: 200;
  margin: 5px;
  border-radius: 5px !important;
  cursor: pointer !important;
  -webkit-transform-origin: center;
  transform-origin: center;
  position: absolute;
  outline: none;
  border: 1px solid #d8dee8 !important;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  line-height: 7px !important;
  padding: 0px 0px 0px 2px !important;
  transition: transform $transition-time cubic-bezier(0, 0, 0.55, 1);
  &:focus {
    outline: 0;
    box-shadow: none;
  }
  &:focus-visible {
    box-shadow: none !important;
    outline: -webkit-focus-ring-color auto 1px !important;
  }
}

.sidebar-btn--expanded {
  transform: translateX($button-expanded-position) rotate(180deg) !important;
  transition: none !important;
  &:focus {
    outline: 0;
    box-shadow: none;
  }
  &:focus-visible {
    box-shadow: none !important;
    outline: -webkit-focus-ring-color auto 1px !important;
  }
}

.sidebar-btn-transition-container-enter,
.sidebar-btn-transition-container-exit-done {
  transform: translateX(0) rotate(0deg);
}
.sidebar-btn-transition-container-enter-active {
  transform: translateX($button-expanded-position) rotate(180deg);
  transition: transform ($transition-time - $button-transition-delay)
    cubic-bezier(0, 0, 0.55, 1) !important;
}
.sidebar-btn-transition-container-enter-done,
.sidebar-btn-transition-container-exit {
  transform: translateX($button-expanded-position) rotate(180deg);
}

.sidebar-btn-transition-container-exit-active {
  transform: translateX(0) rotate(0deg);
  transition: transform $transition-time ease-out !important;
}
