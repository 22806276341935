@import 'scss/_variables.scss';

.contact-feedback-rating-statistic {
  background: white;

  .statistic-item:first-of-type {
    flex-grow: 1;
  }

  .progress {
    flex-grow: 1;
  }

  .middle {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .help {
    margin-left: 0.594rem;
  }
}
