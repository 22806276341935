@import 'scss/_variables.scss';

.filter-modal {
  .modal-content {
    width: 800px;
  }
  .modal-body {
    padding: 0px !important;
  }
}

.unset-box-shadow {
  box-shadow: none !important;
  border: none;
}

.filter-modal-sub-header {
  color: $mainstay-dark-blue;
  font-size: 12.8px;
  font-weight: 600;
  line-height: 155%;
}

.filter-modal-num-input {
  max-width: 48px;
}

.filter-modal-to-field {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

// Removing up/down arrows from number input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
