.icon-button {
  min-width: 3rem;
}
/* Whitespace setting necessary to prevent large buttons from expanding the triage sidebar */
.dashed-outline-button.btn.icon-button {
  white-space: normal;
  .span {
    white-space: normal;
  }
  &.btn-sm {
    padding: 0.5rem;
  }
  &.btn-md {
    padding: 1rem;
  }
  &.btn-lg {
    padding: 1.5rem;
  }
}
